import React from "react";
import {gql} from "apollo-boost";
import {Input, Button, Form, notification} from "antd";

import './card-comment-form.css';
import {useApolloClient, useMutation} from "@apollo/react-hooks";
import {errorNotification} from "../error";

const ADD_COMMENT = gql`
  mutation AddComment($input: CommentInput!) {
      addComment(input: $input){
        label
        message
        comments{
          id
          content
          created_at
          author{
            id
            nickname
          }
        }  
      }
    }`;

const GET_POST = gql`
    query GetPost($slug : String!){
        post(slug : $slug){
            id
            slug
            comments(first : 15){
                pageInfo{
                  startCursor
                  endCursor
                  total
                  currentPage
                  lastPage
                }
                edges {
                    node{
                        id
                        content
                        author{
                            id
                            nickname
                        }
                        created_at
                    }
                }
            }
        }
    }`;

const GET_DASHBOARD_POSTS = gql`
    query Dashboard{
        dashboard{
            pageInfo{
                startCursor
                endCursor
                total
                currentPage
                lastPage
            }
            edges{
                node{
                    id
                    slug
                    comment_count
                    comments(first : 15){
                        pageInfo{
                          startCursor
                          endCursor
                          total
                          currentPage
                          lastPage
                        }
                        edges {
                            node{
                                id
                                content
                                author{
                                    id
                                    nickname
                                }
                                created_at
                            }
                        }
                    }
                }   
            }
        }
    }`;

const commentFormSubmit = ({ event, props, onAddComment }) => {

    event.preventDefault();

    const {
        form,
        //postStatus,
        lastCommentId,
        postId,
    } = props;

    form.validateFields((err, values) => {
        /*
        if(postStatus !== 'public'){

            notification['error']({
                message: "You can't post comment, this post not published",
            });

            return false;
        }

        if(values.comment === undefined){
            notification['error']({
                message: "You can't post empty comment!",
            });

            return false;
        }*/

        onAddComment({
            variables : {
                input : {
                    post_id: postId,
                    content: values.comment,
                    last_comment_id: lastCommentId
                }
            }
        }).catch((error) => {
            errorNotification(error);
        });

    });

};

const NormalCardCommentForm = (props) => {

    const { form, onAddComment, loading } = props;

    const { getFieldDecorator  } = form;

    return (
        <Form
            className="add-comment"
            onSubmit={ (event)=> {
                commentFormSubmit({
                    event,
                    props,
                    onAddComment
                });

                form.setFieldsValue({'comment': ''});
            }}
        >
            {getFieldDecorator('comment')(
                <Input.TextArea
                    autoSize={{ minRows: 1, maxRows: 4 }}
                    placeholder="Add a comment…"
                />
            )}
            <Button
                htmlType="submit"
                loading={ loading }
            >
                Post
            </Button>
        </Form>
    );
};

const CardCommentForm = Form.create({ name: 'post_add_comment' })(NormalCardCommentForm);

export const CardCommentFormContainerPost = (props) => {

    const client = useApolloClient();
    const { postSlug } = props;

    const [onAddComment, { loading }] = useMutation(ADD_COMMENT, {
        update : (cache, { data }) => {

            const {
                addComment : {
                    label,
                    message,
                    comments
                }
            } = data;

            const {post} = client.readQuery({
                query: GET_POST,
                variables: {
                    slug: postSlug,
                }
            });

            const newCommentsNodes = comments.map( (item) => {
                return { node :  item, __typename: "CommentEdge"}
            });

            const { pageInfo } = post.comments;

            client.writeQuery({
                query: GET_POST,
                data: {
                    post: {
                        ...post,
                        comments: {
                            pageInfo: pageInfo,
                            edges: [...newCommentsNodes, ...post.comments.edges ],
                            __typename: "CommentConnection"
                        }
                    },
                },
            });

            notification['success']({
                message: label,
                description: message
            });

        }
    });

    return (
        <CardCommentForm
            {...props}
            onAddComment={onAddComment}
            loading={loading}
        />
    );

};

export const CardCommentFormContainerDashboard = (props) => {

    const client = useApolloClient();
    const { postId } = props;

    const [onAddComment, { loading }] = useMutation(ADD_COMMENT, {
        update : (cache, { data }) => {

            const {
                addComment : {
                    label,
                    message,
                    comments
                }
            } = data;

            const {dashboard} = client.readQuery({
                query: GET_DASHBOARD_POSTS
            });

            const commentedPost = dashboard.edges.find( ({ node }) => node.id === postId);

            const newCommentsNodes = comments.map( (item) => {
                return { node :  item, __typename: "CommentEdge"}
            });

            commentedPost.node.comments.edges = [
                ...newCommentsNodes,
                ...commentedPost.node.comments.edges
            ];

            commentedPost.node.comment_count = commentedPost.node.comments.edges.length;

            client.writeQuery({
                query: GET_DASHBOARD_POSTS,
                data: {
                    dashboard
                },
            });

            notification['success']({
                message: label,
                description: message
            });

        }
    });

    return (
        <CardCommentForm
            {...props}
            onAddComment={onAddComment}
            loading={loading}
        />
    );

};
