import React from "react";
import {Icon, message, notification} from "antd";
import {Link} from "react-router-dom";
import {gql} from "apollo-boost";
import {errorNotification} from "../error";
import {useApolloClient, useMutation} from "@apollo/react-hooks";

import './card-actions.css';

const LIKE = gql`
  mutation Like($post_id : ID!) {
      like(post_id : $post_id){
        label
        message
        liked
        like_count
      }
    }`;

const GET_POST = gql`
    query GetPost($slug : String!){
        post(slug : $slug){
            id
            slug
            status
            liked
            viewed
            like_count
        }
    }`;

const GET_DASHBOARD_POSTS = gql`
    query Dashboard{
        dashboard{
            pageInfo{
                startCursor
                endCursor
                total
                currentPage
                lastPage
            }
            edges{
                node{
                    id
                    slug
                    liked
                    viewed
                    like_count
                    view_count
                    comment_count
                }   
            }
        }
    }`;


const CardActions = (props) => {

    const {
        postSlug,
        liked,
        loadingLike,
        onClickLike,
    } = props;

    return(
        <div className="card-actions">
            <button
                className={ `like-but ${liked ? 'liked' : null}` }
                onClick={ onClickLike }
            >
                <Icon
                    type={ loadingLike ? 'loading' : 'heart' }
                    theme={ liked && !loadingLike ? 'filled' : 'outlined'}
                />
            </button>
            <Link className="message-but" to={`/post/${ postSlug }`} >
                <Icon type="message" />
            </Link>
            <button
                className="share-but"
                onClick={ () => message.warning('Feature in development') }
            >
                <Icon type="share-alt" />
            </button>
            <button
                className="favorite-but"
                onClick={ () => message.warning('Feature in development') }
            >
                <Icon type="book" />
            </button>
        </div>
    );
};


export const CardActionsContainerPost = (props) => {

    const client = useApolloClient();

    const {
        postSlug,
        postId
    } = props;

    const [ onLikeClick, { loading : loadingLike } ] = useMutation(LIKE, {
        update : (cache, { data }) => {

            const {like} = data;
            const {label, message, liked, like_count} = like;

            const {post} = client.readQuery({
                query: GET_POST,
                variables: {
                    slug: postSlug,
                }
            });

            client.writeQuery({
                query: GET_POST,
                data: {
                    post: {...post, liked, like_count}
                },
            });

            notification['success']({
                message: label,
                description: message
            });
        }
    });

    const onClickLike = () => {
        if(!loadingLike) {
            onLikeClick({
                variables: {
                    post_id: postId,
                }
            }).catch((error) => {
                errorNotification(error);
            });
        }
    };

    return (
        <CardActions
            {...props}
            onClickLike={onClickLike}
            loadingLike={ loadingLike }
        />
    );
};

export const CardActionsContainerDashboard = (props) => {

    const client = useApolloClient();

    const {
        postId
    } = props;

    const [ onLikeClick, { loading : loadingLike } ] = useMutation(LIKE, {
        update : (cache, { data : { like } }) => {

            const {label, message, liked, like_count} = like;

            const {dashboard} = client.readQuery({
                query: GET_DASHBOARD_POSTS
            });

            const likedPost = dashboard.edges.find( ({ node }) => node.id === postId);

            likedPost.node.like_count = like_count;
            likedPost.node.liked = liked;

            client.writeQuery({
                query: GET_DASHBOARD_POSTS,
                data: {
                    dashboard
                },
            });

            notification['success']({
                message: label,
                description: message
            });
        }
    });

    const onClickLike = () => {
        if(!loadingLike) {
            onLikeClick({
                variables: {
                    post_id: postId,
                }
            }).catch((error) => {
                errorNotification(error);
            });
        }
    };

    return (
        <CardActions
            {...props}
            onClickLike={onClickLike}
            loadingLike={ loadingLike }
        />
    );
};

//export default CardActions;