import React, { Component } from "react";
import {Button, Modal} from "antd";
import {Link} from "react-router-dom";
import {useApolloClient, useMutation} from "@apollo/react-hooks";
import {gql} from "apollo-boost";

const USER_LOGOUT = gql`
    mutation UserLogout{
       logout {
            message
       }
    }`;

const Popup = ({ visible, hidePopup }) => {

    const [userLogoutRequest] = useMutation(USER_LOGOUT);
    const client = useApolloClient();

    return (
        <Modal
            title={null}
            footer={null}
            visible={ visible }
            closable={false}
            onCancel={hidePopup}
            wrapClassName="modal-actions"
            centered
        >
            <ul className="modal-actions-list">
                <li>
                    <Link to="/profile/change-password">Change password</Link>
                </li>
                <li>
                    <button
                        className="logoutBut"
                        onClick={ () => {

                            userLogoutRequest()
                                .then(() => {
                                    localStorage.clear();
                                    client.resetStore();
                                });
                        } }>
                        Log Out
                    </button>
                </li>
                <li>
                    <button onClick={ hidePopup }>Cancel</button>
                </li>
            </ul>
        </Modal>
    );

};

export default  class UserHeaderActionsSelf extends Component {

    state = {
        visible : false
    };

    showModal = () => {
        this.setState({
            visible: true,
        });
    };

    hideModal = () => {
        this.setState({
            visible: false,
        });
    };

    render(){

        return (
            <div className="user-header-actions">
                <Link className="user-header-but" to="/profile/settings">Edit profile</Link>
                <Button
                    className="user-header-small-but"
                    shape="circle"
                    icon="setting"
                    onClick={this.showModal}
                />
                <Popup visible={ this.state.visible } hidePopup={this.hideModal}/>
            </div>
        );
    };
};

