import React from 'react';
import { useQuery } from "@apollo/react-hooks";

import { ContainerBlock } from "../../wraps";
import UserHeader, { GET_MY_FIELD, GET_USER } from "../../user-header";
import UserTabs from "../../user-tabs";

const UserPage = ({ match, history }) => {

    const { userLogin, userLoginTab } = match.params;

    const { data : { me } } = useQuery(GET_MY_FIELD);

    const {loading, data} = useQuery(GET_USER, {
        variables: { nickname : userLogin },
        fetchPolicy:"cache-and-network"
    });

    const headerProps = loading ?
        { loading : loading } :
        {
            itsMe : me.nickname === userLogin,
            userLogin : userLogin,
            user: data.user
        };

    const userTabProps = loading ?
        { loading : loading } :
        {
            itsMe : me.nickname === userLogin,
            activeTab : userLoginTab,
            history : history,
            userLogin : userLogin,
            userId : data.user.id

        };

    return (
        <ContainerBlock>
            <UserHeader {...headerProps} />
            <UserTabs {...userTabProps} />
        </ContainerBlock>
    );
};

export default UserPage;