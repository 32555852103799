import React, {useState} from "react";
import {Button} from "antd";
import CardMenuPopup from "./card-menu-popup";

import './card-menu.css';

const CardMenu = (props) => {

    const {
        post,
        me,
        singlePost
    } = props;

    const [visiblePopup, togglePopup] = useState(false);

    return(
        <div className="card-menu">
            <Button
                shape="circle"
                icon="ellipsis"
                onClick={ () => togglePopup(true)}
            />
            <CardMenuPopup
                visible={ visiblePopup }
                post={post}
                itsMe={ me.id === post.author.id }
                role={ me.role }
                singlePost={singlePost}
                hidePopup={ () => togglePopup(false) }
            />
        </div>
    );
};

export default CardMenu;