import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { ApolloProvider } from '@apollo/react-hooks';
//import { HttpLink } from 'apollo-link-http';
import { ApolloClient } from 'apollo-client';
import { setContext } from 'apollo-link-context';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { createUploadLink } from 'apollo-upload-client';

import 'antd/dist/antd.css';

import App from './components/app';
import Authentication from "./components/authentication";
import ScrollToTop from './components/service/scrollToTop';
import {typeDefs, resolvers} from  './graphql/resolvers';
import INITIAL_DATA from  './graphql/initial-data';

//const httpLink = new HttpLink({ uri: 'http://we-know-api.mystand-dev.com/graphql' });
//const httpLink = new HttpLink({ uri: 'https://mp-inst.yas.kh.ua/graphql' });
const httpLink = createUploadLink({ uri: 'https://mp-inst.yas.kh.ua/graphql' });

const authLink = setContext((_, { headers }) => {

    const authToken = localStorage.getItem('authToken');

    if(!authToken){
        return { headers: {...headers } }
    }

    return {
        headers: {
           ...headers,
           Accept: "application/json",
           Authorization: `Bearer ${authToken}`,
        }
    }
});

const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache : new InMemoryCache(),
    typeDefs,
    resolvers
});

client.writeData({ data : INITIAL_DATA });

ReactDOM.render(
    <ApolloProvider client={client}>
        <Router>
            <Authentication>
                <ScrollToTop />
                <App />
            </Authentication>
        </Router>
    </ApolloProvider>,
    document.getElementById('root')
);

