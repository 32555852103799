import React from 'react';
import {Link} from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";
import { Col, Row, Spin } from "antd";
import { GET_USER, GET_MY_FIELD } from "../../follow/follow-gql";
import { ContainerBlock } from "../../wraps";
import { FollowListFollowersContainer } from '../../follow';


import './follow.css';

const FollowersPage = ({ match }) => {

    const { userLogin } = match.params;

    const { data : { me } } = useQuery(GET_MY_FIELD);

    const {loading, data } = useQuery(GET_USER, {
        variables: { nickname : userLogin },
        fetchPolicy:"cache-and-network"
    });

    return (
        <ContainerBlock className="followers-page">
            <h2>
                <Link to={ `/${userLogin}` } title="Back to user page">{userLogin}</Link> followers
            </h2>
            <Row type="flex" gutter={0} justify="center">
                <Col xs={24} md={18} xl={14}>
                    { loading ?
                        <div className="item-loader"><Spin size="large" /></div> :
                        <FollowListFollowersContainer
                            user={ data.user}
                            me={ me }
                        />
                    }
                </Col>
            </Row>
        </ContainerBlock>
    );
};

export default FollowersPage;