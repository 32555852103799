import React from "react";
import {Link} from "react-router-dom";
import {useQuery} from "@apollo/react-hooks";
import {Spin} from "antd";
import { GET_PUBLIC_POSTS } from '../user-tabs-gql';

import CardList from "../../card-list";
import {PostCreateFrontLink} from "../../post-create";


const UserPostsTab = ({ authorId, itsMe }) => {

    const {loading, data } = useQuery(GET_PUBLIC_POSTS, {
        variables: {
            status : 'public',
            author_id : authorId
        },
        fetchPolicy:"cache-and-network"
    });

    const {
        posts : {
            edges : posts,
            pageInfo
        } = {}
    } = !loading ? data : {};

    const Posts = () => {

        if(loading) return <div className="item-loader"><Spin size="large" /></div>;

        if(pageInfo.total >= 1 ) {
            return (
                <CardList cardItems={posts} />
            );
        }

        return (
            <div className="user-tabs-message">
                <p>{ itsMe ? 'You' : 'User' } have no published posts</p>
                { itsMe &&
                    <Link to="/post/create">Create new post?</Link>
                }
            </div>
        );

    };

    return (
        <div className="user-tab">
            <Posts />
            { itsMe &&
                <PostCreateFrontLink/>
            }
        </div>
    );
};

export default UserPostsTab;