import React from "react";
import {Link} from "react-router-dom";
import {gql} from "apollo-boost";
import {useQuery} from "@apollo/react-hooks";
import {Spin} from "antd";

import CardList from "../../card-list";

import './dashboard-posts.css';


const GET_DASHBOARD_POSTS = gql`
    query Dashboard{
        dashboard{
            pageInfo{
                startCursor
                endCursor
                total
                currentPage
                lastPage
            }
            edges{
                node{
                    id
                    title
                    description
                    slug
                    status
                    liked
                    viewed
                    like_count
                    view_count
                    comment_count
                    media_layout
                    updated_at
                    created_at
                    attached_file_count
                    author{
                        id
                        nickname
                        avatar
                        initials
                        me_following
                    }
                    media{
                        id
                        name
                        title
                        thumbnail
                        path
                        extension
                        alt
                        order
                    }
                    attachments{
                        id
                        name
                        title
                        display_url
                        extension
                        order
                        description
                        order
                    }
                    comments(first : 15){
                        pageInfo{
                          startCursor
                          endCursor
                          total
                          currentPage
                          lastPage
                        }
                        edges {
                            node{
                                id
                                content
                                author{
                                    id
                                    nickname
                                }
                                created_at
                            }
                        }
                    }
                }   
            }
        }
    }`;

const DashboardPosts = () => {

    const { loading, data } = useQuery(GET_DASHBOARD_POSTS, {
        variables: {
            status : 'public'
        },
        fetchPolicy:"cache-and-network"
    });

    if(loading) return(
        <div className="dashboard-posts">
            <div className="item-loader"><Spin size="large" /></div>
        </div>
    );

    const posts = data.dashboard.edges || [];

    return (
        <div className="dashboard-posts">
            {
                data.dashboard.pageInfo.total < 1 ?
                    <div className="dashboard-message">
                        <p>You have no published posts and you follow nothing</p>
                        <p>
                            <Link to="/post/create">Create new post?</Link>
                        </p>
                        <p>OR</p>
                        <p>
                            <Link to="/posts">Watch all the posts and follow?</Link>
                        </p>
                    </div>:
                    <CardList cardItems={posts} type="dashboard" />
            }
        </div>
    );

};

export default DashboardPosts;