import React from "react";
import {Icon} from "antd";
import {Link} from "react-router-dom";

import './post-create-front-link.css';

const PostCreateFrontLink = () => {
    return (
        <div className="post-create-link-float create-link-float">
            <Link to="/post/create" title="Create new post">
                <Icon type="plus" />
            </Link>
        </div>
    );
};

export default PostCreateFrontLink;