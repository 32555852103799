import React, { Component } from "react";
import {useMutation} from "@apollo/react-hooks";
import {UPDATE_POST} from "../post-update-gql";
import {errorNotification} from "../../error";
import {Button, Form, Input, notification, Radio} from "antd";

import './post-data-form.css';

const handleUpdate = (props) => {

    const {
        onUpdatePost,
        id,
        title,
        description,
        media_layout,
        status,
        redirect
    } = props;

    onUpdatePost({
        variables : {
            post_id: id,
            input: {
                title: title,
                description: description,
                media_layout: media_layout,
                status: status
            }
        },
        update : (cache, { data }) => {

            const {
                updatePost : {
                    label,
                    message
                }
            } = data;

            notification['success']({
                message: label,
                description: message
            });

            redirect();
        }
    }).catch((error) => {
        errorNotification(error);
    });
};

const NormalPostDataForm = (props) => {

    const {
        form,
        post,
        history,
        loadingState
    } = props;

    const { getFieldDecorator  } = form;

    const [updatePost, { loading : updateLoading}] = useMutation(UPDATE_POST);

    const updateProps = {
        onUpdatePost : updatePost,
        id : post.id,
    };

    const PreviewButton = () => {
        return (
            <Button
                loading={updateLoading && loadingState.preview}
                type="primary"
                disabled={!post.media.length}
                onClick={
                    () => {

                        loadingState.set({ previewLoading: true });

                        handleUpdate({
                            ...updateProps,
                            title: form.getFieldValue('post-title'),
                            description: form.getFieldValue('post-description'),
                            media_layout: form.getFieldValue('post-media-layout'),
                            status: 'draft',
                            redirect : () => {
                                history.push(`/post/preview/${post.slug}`)
                            }
                        });
                    }
                }>
                Preview
            </Button>
        );
    };

    const SaveDraftButton = () => {
        return (
            <Button
                loading={updateLoading && loadingState.save}
                type="primary"
                disabled={!post.media.length}
                onClick={
                    () => {

                        loadingState.set({ saveLoading: true });

                        handleUpdate({
                            ...updateProps,
                            title: form.getFieldValue('post-title'),
                            description: form.getFieldValue('post-description'),
                            media_layout: form.getFieldValue('post-media-layout'),
                            status: 'draft',
                            redirect : () => {
                                history.push(`/${post.author.nickname}/drafts`)
                            }
                        });
                    }
                }
            >
                Save as draft
            </Button>
        );
    };

    const PublishButton = () => {
        return (
            <Button
                loading={updateLoading && loadingState.publish}
                type="primary"
                disabled={!post.media.length}
                onClick={
                    () => {

                        loadingState.set({ publishLoading: true });

                        handleUpdate({
                            ...updateProps,
                            title: form.getFieldValue('post-title'),
                            description: form.getFieldValue('post-description'),
                            media_layout: form.getFieldValue('post-media-layout'),
                            status: 'public',
                            redirect : () => {
                                history.push(`/${post.author.nickname}`)
                            }
                        });
                    }
                }
            >
                Publish
            </Button>
        );
    };

    return (
        <div className="post-update-details">
            <h4>Post Details</h4>
            <Form
                className="post-create-form">
                <Form.Item>
                    {getFieldDecorator('post-media-layout', {
                        initialValue: post.media_layout || 'square',
                    })(
                        <Radio.Group>
                            <Radio value="square">square</Radio>
                            <Radio value="portrait">portrait</Radio>
                            <Radio value="landscape">landscape</Radio>
                        </Radio.Group>
                    )}
                </Form.Item>
                <Form.Item>
                    {getFieldDecorator('post-title', {
                        initialValue: post.title,
                    })(
                        <Input placeholder="title" />
                    )}
                </Form.Item>
                <Form.Item>
                    {getFieldDecorator('post-description', {
                        initialValue: post.description,
                    })(
                        <Input.TextArea
                            placeholder="Description"
                            autoSize={{ minRows: 6, maxRows: 10 }}
                        />
                    )}
                </Form.Item>
                <Form.Item>
                    <PreviewButton />
                    <SaveDraftButton />
                    <PublishButton />
                </Form.Item>
            </Form>
        </div>
    );
};

class ContainerPostDataForm extends Component{

    state = {
      previewLoading: false,
      saveLoading: false,
      publishLoading: false
    };

    changeLoadingState = (state) => {
        this.setState({ ...state });
    };

    render() {

        const loadingState = {
            preview: this.state.previewLoading,
            save: this.state.saveLoading,
            publish: this.state.publishLoading,
            set : this.changeLoadingState
        };

        return (
            <NormalPostDataForm
                { ...this.props }
                loadingState={ loadingState }
            />
        );
    }
}

const PostDataForm = Form.create({ name: 'post_update_data' })(ContainerPostDataForm);

export default PostDataForm;