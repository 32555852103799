import React from 'react';
import {Link} from "react-router-dom";
import {ContainerBlock} from "../../wraps";
import FollowingTabs from "../../follow";

import './follow.css';


const FollowingPage = ({ match, history }) => {

    const { userLogin } = match.params;

    return (
        <ContainerBlock className="following-page">
            <h2>
                <Link to={ `/${userLogin}` } title="Back to user page">{ userLogin }</Link> following
            </h2>
            <FollowingTabs
                match={ match }
                history={ history }
            />
        </ContainerBlock>
    );
};

export default FollowingPage;