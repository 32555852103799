import React, {useState} from "react";
import {useApolloClient, useMutation} from "@apollo/react-hooks";
import {Button, Drawer, Icon, notification, Upload} from "antd";
import {ADD_ATTACHMENT, DELETE_ATTACHMENT, GET_POST} from "../post-update-gql";

import './post-upload-attachments.css';
import {errorNotification} from "../../error";

const UploadAttachments = (props) => {

    const {
        defaultAttachments,
        postId,
        postSlug,
        countSendedFiles,
        setCountSendedFiles
    } =  props;

    const client = useApolloClient();

    const [addAttachment, { loading }] = useMutation(ADD_ATTACHMENT);
    const [deleteAttachment] = useMutation(DELETE_ATTACHMENT);

    const { Dragger } = Upload;

    const fileList = defaultAttachments.map(({ id, title, extension, display_url }) => {
        return (
            {
                uid : id,
                name : `${title}.${extension}`,
                url : display_url,
                status : 'done'
            }
        )
    });

    const formAttachmentsProps = {
        beforeUpload :  (file) => {

            setCountSendedFiles(countSendedFiles + 1 );

            addAttachment({
                variables : {
                    post_id : postId,
                    files: [file]
                },
                update(cache, { data }) {

                    const {addAttached} = data;
                    const {label, message} = addAttached;

                    const {post} = client.readQuery({
                        query: GET_POST,
                        variables: {
                            slug: postSlug,
                        }
                    });

                    client.writeQuery({
                        query: GET_POST,
                        data: {
                            post: {...post, ...addAttached.post}
                        },
                    });

                    setCountSendedFiles(countSendedFiles - 1 );

                    notification['success']({
                        message: label,
                        description: message
                    });

                }
            }).catch((error) => {
                setCountSendedFiles(countSendedFiles - 1 );
                errorNotification(error);
            });

            return false;
        },
        onRemove: (file) => {

            if(file.status === 'done') {

                deleteAttachment({
                    variables: {
                        attached_id: file.uid
                    },
                    update: (cache, {data}) => {

                        const {
                            deleteAttached : {
                                label,
                                message
                            }
                        } = data;

                        const {post} = client.readQuery({
                            query: GET_POST,
                            variables: {
                                slug: postSlug,
                            }
                        });

                        const newMedia = post.attachments.filter(item => item.id !== file.uid);

                        client.writeQuery({
                            query: GET_POST,
                            data: {
                                post: {...post, attachments: [...newMedia]}
                            },
                        });

                        notification['success']({
                            message: label,
                            description: message
                        });

                    }
                }).catch((error) => {
                    errorNotification(error);
                });
            }
        },
        fileList: fileList,
        showUploadList: {
            showPreviewIcon: false,
        },
        multiple : true
    };

    console.log(countSendedFiles);


    return(
        <div className="post-update-attachments">
            <Dragger {...formAttachmentsProps}>
                <p className="ant-upload-drag-icon">
                    { loading || countSendedFiles > 0 ? <Icon type="loading" /> : <Icon type="inbox" />}
                </p>
                <p className="ant-upload-text">Click or drag file to this area to upload</p>
                <p className="ant-upload-hint">
                    You can upload any files up to 25MB size.
                </p>
            </Dragger>
        </div>
    );
};

const PostUploadAttachments = (props) => {

    const { defaultAttachments } = props;

    const [countSendedFiles, setCountSendedFiles] = useState(0);
    const [showDrawer, setShowDrawer] = useState(false);


    return (
        <div className="post-update-attachments-wrap">
            <h4>
                <p>Attachments ({ defaultAttachments.length })</p>
                <Button type="dashed" onClick={ () => setShowDrawer( true )}>Add/View</Button>
            </h4>
            <Drawer
                className="post-update-drawer"
                title="Post Attachments"
                placement="right"
                onClose={ () => setShowDrawer( false )}
                visible={showDrawer}
            >
                <UploadAttachments
                    { ...props }
                    countSendedFiles={countSendedFiles}
                    setCountSendedFiles={setCountSendedFiles}
                />
            </Drawer>
        </div>
    );

};

export default PostUploadAttachments;