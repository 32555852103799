import React from "react";
import {useApolloClient, useMutation} from "@apollo/react-hooks";
import {Button, notification} from "antd";
import {errorNotification} from "../error";
import { GET_DASHBOARD_POSTS, FOLLOW_USER, GET_POST } from "./card-menu-gql";

const dashboardFollow = (props) => {

    const {
        author_id,
        onFollowClick,
        client,
    } = props;

    onFollowClick({
        variables: {
            user_id: author_id,
        },
        update : (cache, { data }) => {

            const {
                followUser: {
                    label,
                    message,
                    me_following
                }
            } = data;

            const { dashboard } = client.readQuery({
                query: GET_DASHBOARD_POSTS
            });

            dashboard.edges = dashboard.edges.map( ({ node, __typename }) => {

                if(node.author.id === author_id){
                    node.author.me_following = me_following;
                }

                return { node : node, __typename }
            });

            client.writeQuery({
                query: GET_DASHBOARD_POSTS,
                data: {
                    dashboard
                },
            });

            notification['success']({
                message: label,
                description: message
            });

        }
    }).catch((error) => {
        errorNotification(error);
    });

};

const singleFollow = (props) => {

    const {
        author_id,
        postSlug,
        onFollowClick,
        client
    } = props;

    onFollowClick({
        variables: {
            user_id: author_id,
        },
        update : (cache, { data }) => {

            const {
                followUser:{
                    label,
                    message,
                    me_following
                }
            } = data;

            const { post } = client.readQuery({
                query: GET_POST,
                variables: {
                    slug : postSlug
                },
            });

            post.author.me_following = me_following;

            client.writeQuery({
                query: GET_POST,
                data: {
                    post
                },
            });

            notification['success']({
                message: label,
                description: message
            });


        }
    }).catch((error) => {
        errorNotification(error);
    });

};

const CardMenuActionFollow = (props) => {

    const client = useApolloClient();

    const {
        post,
        singlePost,
    } = props;

    const [ onFollowClick, { loading : followLoading } ] = useMutation(FOLLOW_USER);

    return (
        <li>
            <Button
                loading={ followLoading }
                className={post.author.me_following ? 'red-text' : ''}
                onClick={
                  () => {
                      singlePost ?
                          singleFollow({
                              author_id : post.author.id,
                              postSlug : post.slug,
                              onFollowClick,
                              client
                          }) :
                          dashboardFollow({
                              author_id : post.author.id,
                              onFollowClick,
                              client
                          })
                  }
                }
            >
              { post.author.me_following ? 'Unfollow' : 'Follow' }
            </Button>
        </li>
    )
};

export default CardMenuActionFollow;