import React from 'react';
import {ContainerBlock} from "../../wraps";
import PostCreate from "../../post-create";

const PostCreatePage = () => {
    return (
        <ContainerBlock>
            <PostCreate />
        </ContainerBlock>
    );
};

export default PostCreatePage;