import React from "react";
import {useApolloClient, useMutation} from "@apollo/react-hooks";
import { Button, notification } from "antd";
import { FOLLOW_USER, GET_USER_FOLLOWERS } from "../follow-gql";
import { errorNotification } from "../../error";

const FollowButtonFollowersUser = ({ objId, author }) => {

    const client = useApolloClient();

    const [ onFollowClick, { loading : followLoading } ] = useMutation(FOLLOW_USER, {
        update : (cache, { data }) => {

            const {
                followUser:{
                    label,
                    message,
                    me_following
                }
            } = data;

            const { userFollowers } = client.readQuery({
                query: GET_USER_FOLLOWERS,
                variables: {
                    user_id : objId
                },
            });

            const followUser = userFollowers.edges.find( ({ node }) => node.id === author.id);

            followUser.node.me_following = me_following;

            client.writeQuery({
                query: GET_USER_FOLLOWERS,
                data: {
                    userFollowers
                },
            });

            notification['success']({
                message: label,
                description: message
            });
        }
    });

    return(
        <Button
            type={ author.me_following ? null : "primary" }
            loading={ followLoading }
            onClick={
                () => {
                    onFollowClick({
                        variables: {
                            user_id: author.id,
                        }
                    }).catch((error) => {
                        errorNotification(error);
                    });
                }
            }
        >
            { author.me_following ? 'Following' : 'Follow' }
        </Button>
    );
};

export default FollowButtonFollowersUser;