import React, { Fragment }  from "react";

import { gql } from "apollo-boost";
import { useQuery } from "@apollo/react-hooks";

import './authentication.css';
import { Authorization } from '../pages';

const GET_AUTH_TOKEN = gql`
  query GetAuthToken {
    authToken @client
  }
`;

const Authentication = ({children}) => {

    const { data } = useQuery(GET_AUTH_TOKEN);
    const authToken  = (data !== undefined && data.authToken !== undefined) ? data.authToken : false;

    return (
        <Fragment>
            { authToken ? children : <Authorization /> }
        </Fragment>
    );
};


export default Authentication;
