import React from "react";
import {Tabs} from "antd";

import './profile-tabs.css';

import ProfileTabSettings from './tabs/profile-tab-settings';
import ProfileTabChangePassword from './tabs/profile-tab-change_password';

const changeTab = (history, tabKey) => {
    history.push(`/profile/${tabKey}`);
};


const ProfileTabs = ({ activeTab, history }) => {

    const { TabPane } = Tabs;

    return (
        <div className="profile-tabs">
            <Tabs

                tabPosition="left"
                activeKey={ activeTab ? activeTab : 'settings' }
                onTabClick={ (key) => {
                    changeTab(history, key);
                }}
            >
                <TabPane tab="Settings" key="settings">
                    <ProfileTabSettings />
                </TabPane>
                <TabPane tab="Change Password" key="change-password">
                    <ProfileTabChangePassword />
                </TabPane>
            </Tabs>
        </div>
    );
};


export default ProfileTabs;