import React from 'react';
import {Row} from "antd";
import CardDashboard from "../card-dashboard";
import CardPreview from "../card-preview";

import './card-list.css';


const CardList = ({ cardItems, type }) => {

    const RenderItems = cardItems.map( ({ node }) => {

        const { id } = node;

        if(type === 'dashboard'){
            return (
                <CardDashboard key={id} post={ node } />
            );
        } else if(type === 'draft'){

            return <CardPreview key={id} post={ node } title path={'/post/edit/'} />;

        }

        return <CardPreview key={id} post={ node } counters />;
    });

    return (
        <Row gutter={24} className="card-list">
            { RenderItems }
        </Row>
    );
};

export default CardList;