import {gql} from "apollo-boost";

export const GET_MY_FIELD = gql`
    query GetMe{
        me @client{
            id
            nickname
        }
    }`;

export const GET_USER=gql`
    query GetUser($nickname : String!){
        user(nickname : $nickname){
            id
            nickname
            name
            initials
            email
            status
            locale
            role
            gender
            avatar
            about
            me_following
            counters : user_counters{
                published : public_posts
                followers
                following
            }
        }
    }`;

export const FOLLOW_USER = gql`
  mutation FollowUser($user_id : ID!) {
      followUser(user_id : $user_id){
        label
        message
        me_following
        counters : user_counters{
           published : public_posts
           followers
           following
        }
      }
    }`;