import React from "react";
import { Col, Row } from "antd";

const FollowingTagsTab = ({ itsMe }) => {

    return (
        <div className="following-tab" >
            <Row type="flex" gutter={0} justify="center">
                <Col xs={24} md={18} xl={14}>
                    <div className="following-tabs-message">
                        <p>{ itsMe ? 'You' : 'User' } not Following Tags</p>
                        <div className="in-development">Feature in development</div>
                    </div>
                </Col>
            </Row>
        </div>
    );

};

export default FollowingTagsTab;