import React from 'react';
import {Link} from "react-router-dom";

import './comments-list.css';

const CommentsList = ({comments, amount, setLastCommentId, withTime = true}) => {

    if(!comments.length){
        return null;
    }

    const commentsToShow = (amount) ? comments.slice(0, amount) : comments;

    const RenderItems = commentsToShow.map(({ node }) => {

        const {id, author, content, created_at} = node;

        return (
            <li key={id} className="comment">
                <p>
                    <Link to={`/${ author.nickname }`} className="nick">{author.nickname}</Link>
                    <span className="text">{content}</span>
                </p>
                { withTime ? <p className="comment-date">{ created_at }</p> : null }
            </li>
        );
    });

    setLastCommentId(commentsToShow[0].node.id);

    return (
        <ul className="comments-list">
            { RenderItems }
        </ul>
    );
};

export default CommentsList;