import React from 'react';
import {ContainerBlock} from "../../wraps";
import ProfileTabs from '../../profile-tabs';

import './profile-page.css';

const ProfilePage = ({match, history}) => {

    const { profileTab } = match.params;

    return (
        <ContainerBlock>
            <ProfileTabs
                history={history}
                activeTab={profileTab}
            />
        </ContainerBlock>
    );
};

export default ProfilePage;