import React, {useState} from "react";
import {useApolloClient, useMutation, useQuery} from "@apollo/react-hooks";
import {Link} from "react-router-dom";
import Linkify from "react-linkify";
import {Col, Row, Spin, Button} from "antd";
import { GET_POST, GET_ME, GET_MORE_COMMENTS, UPDATE_POST_VIEW } from './card-post-gql';

import CardMediaSlider from "../card-media-slider";
import CardHero from "../card-hero";
import CommentsList from "../comments-list";
import { CardActionsContainerPost } from "../card-actions";
import CardLikeAmount from "../card-like-amount";
import AttachmentsList from "../attachments-list";
import { CardCommentFormContainerPost } from "../card-comment-form";
import {errorNotification, ErrorResult} from "../error";

import './card-post.css';

const LoadMoreComments = ({ pageInfo, post, postSlug }) => {

    const { endCursor, currentPage, lastPage} = pageInfo;

    const { loading, fetchMore } = useQuery(GET_MORE_COMMENTS,
        {
            variables: {
                slug : postSlug
            },
            notifyOnNetworkStatusChange: true
        });

    if(currentPage === lastPage) return null;

    return (
        <div className="load-more-comment">
            <Button
                loading={loading}
                shape={ loading ? 'circle' : 'round' }
                onClick={
                    () => {

                        fetchMore({
                            variables: {
                                endCursor : endCursor
                            },
                            updateQuery: (previousResult, { fetchMoreResult }) => {

                                const newEdges = fetchMoreResult.post.comments.edges;
                                const pageInfo = fetchMoreResult.post.comments.pageInfo;

                                const newPost = {
                                    post: {
                                        ...post,
                                        comments: {
                                            __typename: post.comments.__typename,
                                            edges: [...post.comments.edges, ...newEdges],
                                            pageInfo
                                        }
                                    }
                                };

                                return newEdges.length ? newPost : previousResult;
                            }
                        }).catch((error) => {
                            errorNotification(error);
                        });
                    }
                }
            >
                <span className="label">Load more</span>
            </Button>
        </div>
    );
};

const CardPostViews = ({ post }) => {

    const { slug, view_count } = post;

    const client = useApolloClient();
    const [updatePostView, { loading }] = useMutation(UPDATE_POST_VIEW, {
        update : (cache, { data }) => {

            const {postView} = data;
            const {viewed, view_count} = postView;

            const {post} = client.readQuery({
                query: GET_POST,
                variables: {
                    slug: slug,
                }
            });

            client.writeQuery({
                query: GET_POST,
                data: {
                    post: {...post, viewed, view_count}
                },
            });

        }
    });

    if(!loading && !post.viewed && post.status === 'public'){
        updatePostView({
            variables: {
                post_id: post.id,
            },
        }).catch((error) => {
            errorNotification(error);
        });
    }

    return (
        <span className="view-amount">
            { view_count > 1 ?
                `${view_count} views` :
                `${view_count} view`
            }
        </span>
    );

};

const CardPost = (props) => {

    const { postSlug } = props;

    const [ lastCommentId, setLastCommentId ] = useState('');

    const {data : { me } } = useQuery(GET_ME);

    const {loading, error, data } = useQuery(GET_POST, {
        variables: { slug : postSlug },
        fetchPolicy:"cache-and-network",
    });

    if(loading) return (
        <div className="post">
            <div className="item-loader"><Spin size="large" /></div>
        </div>
    );

    if(error || data.post === null) {
        return (
            <ErrorResult
                error={error}
                goToLink="/"
                goToText="Go to dashboard"
            />
        );
    }

    const { post } = data;
    const { attachments } = post;
    const comments = post.comments.edges || [];

    const componentDecorator = (href, text, key) => (
        <a href={href} key={key} target="_blank" rel="noopener noreferrer">
            {text}
        </a>
    );

    return (
        <div className={ `card-post post-status-${post.status}`} >
            <Row type="flex" justify="center">
                {
                    post.status !== 'public' ?
                        <Col span={24}>
                            <div className="post-status">
                                Post status : <span>{ post.status }</span>
                                {post.status === 'draft' ?
                                    <Link to={`/post/edit/${post.slug}`}>edit > </Link> : null
                                }
                            </div>
                        </Col> : null
                }
                <Col xs={24} sm={24} md={15} span={15} className="card-post-media">
                    <CardMediaSlider media={ post.media } />
                </Col>
                <Col xs={24} sm={24} md={9} span={9} className="card-post-data">
                    <CardHero
                        post={ post }
                        me={me}
                        singlePost
                    />
                    <div className="card-post-body">

                        {post.title || post.description ?
                            <div className="card-post-content">
                            { post.title ?
                                <h3 className="card-post-title">{post.title}</h3> : null
                            }
                            { post.description ?
                                <p>
                                    <Linkify componentDecorator={ componentDecorator }>
                                        {post.description}
                                    </Linkify>
                                </p> : null
                            }
                            </div> : null
                        }
                        { comments ?
                            <div className="card-post-comments">

                                <CommentsList
                                    comments={comments}
                                    setLastCommentId={setLastCommentId}
                                />
                                <LoadMoreComments
                                    pageInfo={ post.comments.pageInfo }
                                    post={ post }
                                    postSlug={ postSlug }
                                />
                            </div> : null
                        }
                    </div>
                    <div className="card-post-footer">
                        <div>
                            <CardActionsContainerPost
                                postId={ post.id }
                                postSlug={ post.slug }
                                liked={ post.liked }
                            />
                            <div className="card-add-info">
                                <CardLikeAmount
                                    likeCount={ post.like_count }
                                    postId={ post.id }
                                    myId={ me.id }
                                />
                                <CardPostViews
                                    post={ post }
                                />
                            </div>
                        </div>
                        <div className="add-comment-wrap">
                            <CardCommentFormContainerPost
                                postId={ post.id }
                                postStatus={ post.status }
                                postSlug={ post.slug }
                                lastCommentId={lastCommentId}
                            />
                        </div>
                    </div>
                </Col>
            </Row>
            { attachments.length ?
                <Row type="flex" justify="center">
                    <Col span={24} >
                        <div className="post-attachments">
                            <h3>Attachments</h3>
                            <AttachmentsList attachments={ attachments } />
                        </div>
                    </Col>
                </Row>
            : null}
        </div>
    );
};

export default CardPost;