import React from "react";
import {Icon} from "antd";

import './attachments-list.css';

const AttachIcon = ( { extension } ) => {

    switch (extension) {
        case 'pdf' :
            return <Icon type="file-pdf" />;

        case 'xls' :
        case 'xlsx':
            return <Icon type="file-excel" />;

        case 'zip' :
        case '7zip':
        case '7zp' :
        case 'rar' :
            return <Icon type="file-zip" />;

        case 'doc' :
        case 'docx':
            return <Icon type="file-word" />;

        case 'ppt' :
        case 'pptx':
            return <Icon type="file-ppt" />;

        case 'txt' :
        case 'list':
            return <Icon type="file-text" />;

        case 'jpg':
        case 'gif':
        case 'png':
        case 'tif':
            return <Icon type="file-image" />;

        default: return <Icon type="file" />;
    }
};

const AttachmentsList = ({ attachments }) => {

    const list = attachments.map( ({ id, title, display_url, extension  }) => {
        return (
            <li key={id}>
                <a
                    href={display_url}
                    target="_blank"
                    title={title}
                    rel="noopener noreferrer"
                >
                    <AttachIcon extension={extension}  />
                    { `${title}.${extension}` }
                </a>
            </li>
        );
    });


    return (
       <ul className="attachments-list">
           { list }
       </ul>
    );
};

export default AttachmentsList;
