import React from "react";
import {Link} from "react-router-dom";
import {Icon} from "antd";

import './collection-create-front-link.css';

const CollectionCreateFrontLink = () => {
    return (
        <div className="collection-create-link-float create-link-float">
            <Link to="/collection/create" title="Create new collection">
                <Icon type="folder-add" />
            </Link>
        </div>
    );
};

export default CollectionCreateFrontLink;