import React from 'react';
import { Switch, Route } from 'react-router-dom';
import {useApolloClient, useQuery} from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { Result, Spin, Button } from 'antd';

import './app.css';

import Header from '../header';
import Pages from '../pages';

const GET_CURRENT_USER = gql`
    query GetMe {
        me{
            id
            nickname
            name
            initials
            email
            locale
            role
            gender
            avatar
            about
        }
    }`;

const App = () => {

    const { loading, error } = useQuery(GET_CURRENT_USER);
    const client = useApolloClient();

    if(error) {
        return(
            <Result
                status="500"
                title="ERROR"
                subTitle="Sorry something went wrong. Please try to reload page or contact with administrator."
                extra={
                    <Button
                        type="primary"
                        onClick={
                            () => {
                                localStorage.clear();
                                client.resetStore();
                            }
                        }>
                        Back Home
                    </Button>
                }
            />
        );
    }

    return (
        <React.Fragment>
            { loading ?
                <div className="block-loader"><Spin size="large" tip="Loading..." /></div> :
                <div >
                    <Header />
                    <main className="main-block">
                        <Switch>
                            <Route path="/" component={Pages.Home} exact/>
                            <Route path="/posts" component={Pages.PostsPage} exact/>
                            <Route path="/post/create" component={Pages.PostCreatePage} exact/>
                            <Route path="/post/edit/:postSlug" component={Pages.PostUpdatePage} exact/>
                            <Route path="/post/preview/:postSlug" component={Pages.PostPage} exact/>
                            <Route path="/post/:postSlug" component={Pages.PostPage} exact/>
                            <Route path="/profile" component={Pages.Profile} exact/>
                            <Route path="/collection/create" component={Pages.CollectionCreatePage} exact/>
                            <Route path="/collection/edit/:collectionSlug" component={Pages.CollectionCreatePage} exact/>
                            <Route path="/profile/:profileTab" component={Pages.Profile} exact/>
                            <Route path="/:userLogin/followers" component={Pages.FollowersPage} exact/>
                            <Route path="/:userLogin/following/:followTab" component={Pages.FollowingPage} exact/>
                            <Route path="/:userLogin" component={Pages.UserPage} exact/>
                            <Route path="/:userLogin/:userLoginTab" component={Pages.UserPage} exact/>
                        </Switch>
                    </main>
                </div>
            }
        </React.Fragment>
    );
};

export default App;