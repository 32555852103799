import React from "react";
import AvatarBlock from "../avatar";
import CardMenu from "../card-menu";

import './card-hero.css';

const CardHero = (props) => {

   const { post } = props;

   return (
        <div className="card-hero">
            <AvatarBlock
                photoUrl={post.author.avatar}
                initials={post.author.initials}
                link={`/${post.author.nickname}`}
                label={post.author.nickname}
                dataClass="date"
                text={post.created_at}
            />
            <CardMenu { ...props } />
        </div>
   );
};

export default CardHero;