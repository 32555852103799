import React from "react";
import {Col, Row} from "antd";
import AvatarBlock from "../../avatar";
import {gql} from "apollo-boost";
import {useQuery} from "@apollo/react-hooks";
import ChangePasswordForm from "../../forms/change-password-form";

const GET_ME = gql`
    query GetMe {
        me @client{
            nickname
            name
            initials
            avatar
        }
    }`;

const ProfileTabChangePassword = () => {

    const { data } = useQuery(GET_ME);
    const { me } = data;

    return (
        <div>
            <Row type="flex" className="profile-row">
                <Col lg={22} xl={20} span={24}>
                    <div className="profile-avatar">
                        <AvatarBlock
                            photoUrl={ me.avatar }
                            initials={ me.initials }
                            label={ me.nickname }
                            dataClass="profile"
                            size={50}
                        />
                    </div>
                    <ChangePasswordForm />
                </Col>
            </Row>
        </div>
    );
};

export default ProfileTabChangePassword;