import React from "react";
import {gql} from "apollo-boost";
import {useQuery} from "@apollo/react-hooks";
import {Spin} from "antd";
import CardList from "../../card-list";

const GET_PUBLIC_POSTS = gql`
    query GetPublicPosts($status: Status!, $author_id: ID){
        posts(status: $status, author_id: $author_id){
            pageInfo{
              count
              total
            }
            edges{
                 node{
                    id
                    title
                    description
                    slug
                    status
                    media{
                        id
                        name
                        title
                        thumbnail
                        path
                        extension
                        alt
                        order
                    }
                    like_count
                    comment_count
                    attached_file_count 
                }
            }
        }   
    }`;

const MostRecentPosts = () => {

    const { loading, data } = useQuery(GET_PUBLIC_POSTS, {
        variables: {
            status : 'public'
        },
        fetchPolicy:"cache-and-network"
    });

    if(loading) return(
        <div className="posts-group">
            <div className="item-loader"><Spin size="large" /></div>
        </div>
    );

    const posts = data.posts.edges || [];

    if(!posts.length) return null;

    return (
        <div className="posts-group">
            <h2 className="posts-group-title" >Most recent</h2>
            <CardList cardItems={posts} />
        </div>
    );

};

export default MostRecentPosts;