import React from "react";
import {useQuery} from "@apollo/react-hooks";
import {Col, Row, Spin} from "antd";

import { GET_USER_FOLLOWING } from "../../follow-gql";

import { FollowButtonFollowingUser } from "../../follow-button";
import FollowList from "../../follow-list/follow-list";


const FollowingUsersTab = ({ me, user, itsMe }) => {

    const { loading, data } = useQuery(GET_USER_FOLLOWING, {
        variables: {
            user_id : user.id
        },
        fetchPolicy: "cache-and-network"
    });

    const nodes = (!loading) ?
        data.userFollowing.edges.map( ({ node }) => {
            return {
                node : {
                    id: node.id,
                    author: node
                }
            }
        }) : [];

    const Follow = () => {

        if(loading) return <div className="item-loader"><Spin size="large"/></div>;

        if(!loading && nodes.length){
            return(
                <FollowList
                    items={nodes}
                    myId={me.id}
                    objId={user.id}
                    FollowButton={FollowButtonFollowingUser}
                />
            );
        }

        return(
            <div className="following-tabs-message">
                <p>{ itsMe ? 'You' : 'User' } not Following Users</p>
            </div>
        );


    };

    return (
        <div className="following-tab" >
            <Row type="flex" gutter={0} justify="center">
                <Col xs={24} md={18} xl={14}>
                    <Follow />
                </Col>
            </Row>
        </div>
    );
};

export default FollowingUsersTab;