import React from "react";
import { CollectionCreateFrontLink } from './../../collections';

const UserCollectionsTab = ({ itsMe }) => {
    return (
        <div className="user-tab">
            <div className="user-tabs-message">
                <p>{ itsMe ? 'You' : 'User' } have no Collections</p>
                <div className="in-development">Feature in development</div>
            </div>
            { itsMe &&
                <CollectionCreateFrontLink />
            }
        </div>
    );
};

export default UserCollectionsTab;