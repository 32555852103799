import React, {useState} from "react";
import {Button, Modal, notification} from "antd";
import {useApolloClient, useMutation} from "@apollo/react-hooks";
import {errorNotification} from "../error";
import { GET_DASHBOARD_POSTS, TRASH_POST } from './card-menu-gql'

const dashboardDelete = (props) => {

    const {
        postId,
        trashPost,
        client,
        toggleConfirmModal,
        hidePopup
    } = props;

    trashPost({
        variables: {
            post_id: postId,
        },
        update : (cache, { data }) => {

            const {
                trashPost: {
                    label,
                    message
                }
            } = data;

            const {dashboard} = client.readQuery({
                query: GET_DASHBOARD_POSTS
            });

            dashboard.edges = dashboard.edges.filter(({node}) => node.id !== postId);

            client.writeQuery({
                query: GET_DASHBOARD_POSTS,
                data: {
                    dashboard
                },
            });

            toggleConfirmModal(true);
            hidePopup();

            notification['success']({
                message: label,
                description: message
            });

        }
    }).catch((error) => {
        errorNotification(error);
    });

};

const singleDelete = (props) => {

    const {
        history,
        postId,
        trashPost
    } = props;

    trashPost({
        variables: {
            post_id: postId,
        },
        update : (cache, { data }) => {

            const {
                trashPost:{
                    label,
                    message
                }
            } = data;

            notification['success']({
                message: label,
                description: message
            });

            history.push(`/`);

        }
    }).catch((error) => {
        errorNotification(error);
    });

};

const CardMenuDeleteAction = ( props ) =>{

    const {
        history,
        hidePopup,
        post,
        singlePost,
    } = props;

    const client = useApolloClient();

    const [visibleConfirmModal, toggleConfirmModal] = useState(false);
    const [trashPost, { loading : trashLoading }] = useMutation(TRASH_POST);

    return (
        <li>
            <Button
                onClick={ () => toggleConfirmModal(true) }
            >
                Delete Post
            </Button>
            <Modal
                title="Remove post"
                visible={ visibleConfirmModal }
                onOk={
                    () => {
                        singlePost ?
                            singleDelete({
                                postId : post.id,
                                trashPost,
                                history
                            }) :
                            dashboardDelete({
                                postId : post.id,
                                trashPost,
                                toggleConfirmModal,
                                hidePopup : () => hidePopup(),
                                client
                            })
                    }
                }
                onCancel={() => toggleConfirmModal(false)}
                okText={'Yes'}
                okButtonProps={{ loading : trashLoading }}
                okType={'danger'}
                cancelText={'No'}
            >
                <p>Are you sure you want to delete this post?</p>
            </Modal>
        </li>
    );
};

export default CardMenuDeleteAction;