import React from 'react';
import {ContainerBlock} from "../../wraps";
import CardPost from "../../card-post";

const PostPage = ({ match }) => {

    const { postSlug } = match.params;

    return (
        <ContainerBlock className="card-post-wrap">
            <CardPost postSlug={ postSlug }/>
        </ContainerBlock>
    );
};

export default PostPage;