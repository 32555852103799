import React from 'react';
import { Link } from 'react-router-dom';
import { Affix } from 'antd';
import HeaderActions from "../header-actions";

import SearchBlock from "../search";
import { ContainerBlock } from '../wraps';

import './header.css';

import logo from '../../assets/logo_45pix.png';


const Header = () => {
    return (
        <Affix className="header-fixer">
            <header>
                <ContainerBlock>
                    <div className="header-row">
                        <div className="left-side">
                            <div className="logo-img">
                                <Link to="/">
                                    <img src={logo} alt="logo" />
                                </Link>
                            </div>
                        </div>
                        <div className="center-side">
                            <SearchBlock />
                        </div>
                        <div className="right-side">
                            <HeaderActions />
                        </div>
                    </div>
                </ContainerBlock>
            </header>
        </Affix>
    );
};

export default Header;