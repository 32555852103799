import AuthorizationPage from './authorization/authorization-page';
import HomePage from './home/home-page';
import UserPage from "./user/user-page";
import PostCreatePage from "./post-create/post-create-page";
import PostUpdatePage from "./post-update/post-update-page";
import PostPage from './post/post-page';
import PostsPage from "./posts/posts-page";
import ProfilePage from './profile/profile-page';
import FollowersPage from "./follow/followers-page";
import FollowingPage from "./follow/following-page";
import CollectionCreatePage from "./collection/collection-create-page";


const Pages = {
    Authorization: AuthorizationPage,
    Home: HomePage,
    PostPage: PostPage,
    PostsPage: PostsPage,
    PostCreatePage: PostCreatePage,
    PostUpdatePage: PostUpdatePage,
    UserPage: UserPage,
    FollowersPage: FollowersPage,
    FollowingPage: FollowingPage,
    Profile: ProfilePage,
    CollectionCreatePage: CollectionCreatePage,
};

export default Pages;

export {
    AuthorizationPage as Authorization,
}