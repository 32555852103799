import React from "react";
import {Col, Row, Upload, notification} from "antd";
import ProfileForm from '../../forms/profile-form';
import AvatarBlock from "../../avatar";
import {gql} from "apollo-boost";
import {useMutation, useQuery} from "@apollo/react-hooks";
import {errorNotification} from "../../error";

const GET_ME = gql`
    query GetMe {
        me @client{
            id
            nickname
            name
            initials
            email
            locale
            role
            gender
            avatar
            about
        }
    }`;

const UPLOAD_USER_AVATAR = gql`
  mutation UploadAvatar($user_id: ID!, $file: Upload!) {
      uploadAvatar(user_id: $user_id, file: $file){
        label
        message 
        avatar
      }
    }`;

const ProfileTabSettings = () => {

    const [userUploadAvatar, { loading }] = useMutation(UPLOAD_USER_AVATAR,
        {
            update(cache, { data }) {

                const { me } = cache.readQuery({ query: GET_ME });

                const {
                    uploadAvatar : {
                        label,
                        message,
                        avatar
                    }
                } = data;

                cache.writeQuery({
                    query: GET_ME,
                    data: {
                        me: {...me, avatar}
                    },
                });

                notification['success']({
                    message: label,
                    description: message
                });
            }
        });

    const { data } = useQuery(GET_ME);
    const { me } = data;

    const uploadProps = {
        accept: 'image/*',
        beforeUpload: (file) => {
            userUploadAvatar({
                variables: {
                    user_id: me.id,
                    file: file
                }
            }).catch((error) => {
                errorNotification(error);
            });

            return false;
        },
        showUploadList: false
    };

    return (
        <div>
            <Row type="flex" className="profile-row">
                <Col lg={22} xl={20} span={24}>
                    <div className="profile-avatar">
                        <AvatarBlock
                            photoUrl={ me.avatar }
                            initials={ me.initials }
                            label={ me.nickname }
                            dataClass="profile"
                            size={50}
                            loading={loading}
                            avatarAction={
                                <Upload {...uploadProps}>
                                    <button className="upload-avatar-but">Change Profile Photo</button>
                                </Upload>
                            }
                        />
                    </div>
                    <ProfileForm myData={ data.me } />
                </Col>
            </Row>
        </div>
    );
};

export default ProfileTabSettings;