import React from "react";
import {useMutation} from "@apollo/react-hooks";
import {gql} from "apollo-boost";
import {Link} from "react-router-dom";
import {errorNotification} from "../../error";
import {Button, Form, Icon, Input, notification} from "antd";

import './registration-form.css';
import logo from "../../../assets/logo_45pix.png";


const USER_REGISTRATION = gql`
  mutation RegisterUser($input: RegisterUserInput!) {
      registerUser(input: $input){
        label
        message
      }
    }
`;

const formSubmit = ({ event, form, registrationRequest }) => {
    event.preventDefault();

    form.validateFields((err, values) => {
        if (!err) {
            registrationRequest({
                variables : {
                    input: {
                        name: values.fullName,
                        nickname: values.nickname,
                        email: values.email,
                        password: values.password
                    }
                }
            }).catch((error) => {
                errorNotification(error);
            });
        }
    });

};

const NormalRegistrationForm = ({ form, history }) => {

    const { getFieldDecorator } =  form;

    const [registrationRequest, {loading}] = useMutation(USER_REGISTRATION, {
            update(cache, { data }) {

                const {
                    registerUser : {
                        label,
                        message
                    }
                } = data;

                notification['success']({
                    message: label,
                    description: message,
                    duration: 10
                });

                history.push(`/`);
            }
        }
        );

    return (
        <div className="form-holder">
            <div className="logo-img">
                <img src={logo} alt="logo" />
            </div>
            <h2>Sign up</h2>
            <Form onSubmit={(event) => {

                formSubmit({ event, form, registrationRequest });

            }} className="registration-form">
                <Form.Item>
                    {getFieldDecorator('email', {
                        rules: [
                            {
                                type: 'email',
                                message: 'The input is not valid E-mail',
                            },
                            {
                                required: true,
                                message: 'Please input your Email'
                            }
                        ],
                    })(
                        <Input
                            prefix={<Icon type="mail" className="form-icon" />}
                            placeholder="Email"
                        />,
                    )}
                </Form.Item>
                <Form.Item>
                    {getFieldDecorator('fullName', {
                        rules: [
                            { min: 3, message: 'Full name must be at least 3 characters' },
                            { required: true, message: 'Please input your Full name' }
                        ],
                    })(
                        <Input
                            prefix={<Icon type="solution" className="form-icon" />}
                            placeholder="Full name"
                        />,
                    )}
                </Form.Item>
                <Form.Item>
                    {getFieldDecorator('nickname', {
                        rules: [
                            { min: 3, message: 'Username must be at least 3 characters' },
                            {
                                type: "string",
                                pattern: /^[a-z_.-]+$/,
                                message: 'May contain only small letters, "-", "_", "."'
                            },
                            { required: true, message: 'Please input Username'}
                        ],
                    })(
                        <Input
                            prefix={<Icon type="user" className="form-icon" />}
                            placeholder="Username"
                        />,
                    )}
                </Form.Item>
                <Form.Item>
                    {getFieldDecorator('password', {
                        rules: [
                            { min: 8, message: 'Password must be at least 8 characters' },
                            { required: true, message: 'Please input your Password' }
                        ],
                    })(
                        <Input.Password
                            prefix={<Icon type="lock" className="form-icon" />}
                            type="password"
                            placeholder="Password"
                        />,
                    )}
                </Form.Item>
                <Form.Item>
                    <Button
                        type="primary"
                        htmlType="submit"
                        className="registration-submit"
                        loading={loading}
                    >
                        Sign up
                    </Button>
                </Form.Item>
            </Form>
            <div className="divider">
                <div /><div>OR</div><div />
            </div>
            <div className="sign-link-wrap">
                <p>You have account? </p>
                <Link to="/" className="sign-link">Sign In</Link>
            </div>
        </div>
    );
};


const RegistrationForm = Form.create({ name: 'registration' })(NormalRegistrationForm);

export default RegistrationForm;