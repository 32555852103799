import React from "react";
import { Link, withRouter } from "react-router-dom";
import { Modal } from "antd";

import CardMenuDeleteAction from "./card-menu-popup-delete";
import CardMenuActionFollow from "./card-menu-popup-follow";

const CardMenuPopup = (props) => {

    const {
        visible,
        hidePopup,
        post,
        role,
        singlePost,
        itsMe
    } = props;

    return (
        <Modal
            title={null}
            footer={null}
            visible={ visible }
            closable={ false }
            onCancel={ hidePopup }
            wrapClassName="modal-actions"
            centered
        >
            <ul className="modal-actions-list">
                {!itsMe ?
                    <CardMenuActionFollow { ...props } /> : null
                }
                { !singlePost ?
                    <li>
                        <Link to={`/post/${post.slug}`}>Go to post</Link>
                    </li> : null
                }
                { role !== 'user' || itsMe ?
                    <CardMenuDeleteAction { ...props } /> : null
                }
                <li>
                    <button onClick={ hidePopup }>Cancel</button>
                </li>
            </ul>
        </Modal>
    );

};

export default withRouter(CardMenuPopup);