import React from "react";


const UserTeamsTab = ({ itsMe }) => {
    return (
        <div className="user-tab">
            <div className="user-tabs-message">
                <p>{ itsMe ? 'You' : 'User' } have no Teams</p>
                <div className="in-development">Feature in development</div>
            </div>
        </div>
    );
};

export default UserTeamsTab;