import React from "react";
import {Button, Form, Input, notification, Select} from "antd";
import { gql } from "apollo-boost";
import {useMutation, useQuery} from "@apollo/react-hooks";
import {errorNotification} from "../../error";

import './profile-form.css';


const GET_LANGUAGES = gql`
  query  GetLanguages {
    languageActive {
        id
        name
        slug
    }
  }
`;

const USER_UPDATE = gql`
  mutation UpdateUser($user_id: ID!, $input: UserUpdateInput!) {
      updateUser(user_id: $user_id, input: $input){
        label
        message
        user{
            name
            nickname
            initials
            locale
            gender
            about
        }
      }
    }
`;

const GET_ME = gql`
    query GetMe {
        me {
            id
            name
            nickname
            initials
            locale
            gender
            about
        } 
    }`;

const formSubmit = ({ event, form, userUpdateRequest, myData }) => {
    event.preventDefault();

    form.validateFields((err, values) => {
        if (!err) {
            userUpdateRequest({
                variables : {
                    user_id: myData.id,
                    input: {
                        name: values.fullName,
                        nickname: values.nickname,
                        locale: values.language,
                        gender: values.gender,
                        about: values.about
                    }
                }
            }).catch((error) => {
                errorNotification(error);
            });

        }
    });
};

const NormalProfileForm = ({ myData, form }) => {

    const [userUpdateRequest, { loading }] = useMutation(USER_UPDATE,
        {
            update: function (cache, {data}) {

                const {me} = cache.readQuery({query: GET_ME});

                const {
                    updateUser : {
                        label,
                        message,
                        user
                    }
                } = data;

                cache.writeQuery({
                    query: GET_ME,
                    data: {
                        me: {...me, ...user}
                    },
                });

                notification['success']({
                    message: label,
                    description: message,
                });
            }
        });
    const { getFieldDecorator } =  form;

    const { Option } = Select;
    const { TextArea } = Input;

    const { error: langError, loading : langLoading, data} = useQuery(GET_LANGUAGES);

    let options = null;

    if(!langLoading && !langError) {

        options = data.languageActive.map(({id, slug, name}) => {
            return <Option value={slug} key={id}>{ name }</Option>;
        })

    }

    const formItemLayout ={
        labelCol: { span: 6 },
        wrapperCol: { span: 16 },
    };

    return (
        <div>
            <Form
                onSubmit={(event) => {
                    formSubmit({ event, form, userUpdateRequest, myData });
                }}
                className="profile-form"
                layout="horizontal"
            >
                <Form.Item label="Full Name" {...formItemLayout}>
                    {getFieldDecorator('fullName', {
                        rules: [
                            { min: 3, message: 'Full name must be at least 3 characters' },
                            { required: true, message: 'Please input your Full name' }
                        ],
                        initialValue: myData.name
                    })(
                        <Input placeholder="Enter your Full name" />,
                    )}
                </Form.Item>
                <Form.Item label="Nickname" {...formItemLayout}>
                    {getFieldDecorator('nickname', {
                        rules: [
                            { min: 3, message: 'Nickname must be at least 3 characters' },
                            {
                                type: "string",
                                pattern: /^[a-z_.-]+$/,
                                message: 'May contain only small letters, "-", "_", "."'
                            },
                            { required: true, message: 'Please input Nickname'}
                        ],
                        initialValue: myData.nickname
                    })(
                        <Input pplaceholder="Enter your nickname"/>
                    )}
                </Form.Item>
                <Form.Item label="Email" {...formItemLayout}>
                    {getFieldDecorator('email', {
                        rules: [
                            {
                                type: 'email',
                                message: 'The input is not valid E-mail',
                            },
                            {
                                required: true,
                                message: 'Please input your Email'
                            }
                        ],
                        initialValue: myData.email
                    })(
                        <Input placeholder="Enter your email"  disabled />,
                    )}
                </Form.Item>
                <Form.Item label="Gender"  {...formItemLayout}>
                    {getFieldDecorator('gender', {
                        rules: [{ required: true, message: 'Please select your gender' }],
                        initialValue: myData.gender
                    })(
                        <Select placeholder="Select your gender">
                            <Option value="male">Male</Option>
                            <Option value="female">Female</Option>
                        </Select>
                    )}
                </Form.Item>
                <Form.Item label="Language"  {...formItemLayout}>
                    {getFieldDecorator('language', {
                        rules: [{ required: true, message: 'Please select language' }],
                        initialValue: myData.locale
                    })(
                        <Select
                            loading={langLoading}
                            placeholder="Select your Language"
                        >
                            {
                                options
                            }
                        </Select>
                    )}
                </Form.Item>
                <Form.Item label="About" {...formItemLayout}>
                    {getFieldDecorator('about', {
                        initialValue: myData.about
                    })(
                        <TextArea
                            placeholder="Write something about you"
                            autoSize={{ minRows: 6, maxRows: 10 }}
                        />
                    )}
                </Form.Item>
                <Form.Item  wrapperCol={{ span: 16, offset: 6 }} >
                    <Button loading={loading} type="primary" htmlType="submit">Save</Button>
                </Form.Item>
            </Form>
        </div>
    );
};

const ProfileForm = Form.create({ name: 'update_profile' })(NormalProfileForm);

export default ProfileForm;