import React from 'react';
import { Switch, Route } from 'react-router-dom';
import LoginForm from '../../forms/login-form';
import ForgotPasswordForm from "../../forms/forgot-password-form";
import RegistrationForm from "../../forms/registration-form";

import './authorization-page.css';


const AuthorizationPage = ({ history }) => {
    return (
        <main className="authorization-page">
            <Switch>
                <Route path="/registration/" render={({ history }) => <RegistrationForm history={history} />} />} exact />
                <Route path="/forgot-password/" render={() => <ForgotPasswordForm />} />} exact />
                <Route render={() => <LoginForm />} />
            </Switch>
        </main>
    );
};

export default AuthorizationPage;