import React from "react";
import {useQuery} from "@apollo/react-hooks";
import {gql} from "apollo-boost";
import {Spin} from "antd";

import CardList from "../../card-list";

import './top-posts.css';


export const GET_TOP_POST = gql`
    query TopPost{
        topPost{
            id
            title
            slug
            comment_count
            like_count
            view_count
            attached_file_count
            media{
                id
                name
                title
                thumbnail
                path
                extension
                alt
                order
            }
        }
    }`;


const TopPosts = () => {

    const {loading, data } = useQuery(GET_TOP_POST, {
        fetchPolicy:"cache-and-network"
    });

    if(loading) return(
        <div className="posts-group">
            <div className="item-loader"><Spin size="large" /></div>
        </div>
    );

    if(!data.topPost.length) return null;

    const posts = data.topPost.map( item => {
        return { node : {...item} }
    });

    return (
        <div className="posts-group">
            <h2 className="posts-group-title" >Top posts</h2>
            <CardList cardItems={posts} />
        </div>
    );

};

export default TopPosts;