import React from "react";
import {Avatar, Icon} from "antd";
import { Link } from "react-router-dom";

import './avatar.css';

const AvatarPhoto = (props) => {

    const {
        photoUrl,
        initials,
        link,
        avatarLink = !!link,
        size = 40,
        loading = false
    } = props;

    if(loading){
        return (
            <Avatar size={size}>
                <Icon type="loading" />
            </Avatar>
        );
    }

    if(avatarLink){
        return (
            <Link to={ link }>
                <Avatar src={ photoUrl } size={size}>{ initials }</Avatar>
            </Link>
        );
    }

    return (
        <Avatar src={ photoUrl } size={size}>{ initials }</Avatar>
    );
};

const AvatarBlock = (props) => {

    const {
        label,
        link,
        text,
        dataClass = "",
        labelLink = !!link,
        avatarAction
    } = props;

    const labelItem = labelLink ? <Link to={ link } className="avatar-link">{ label }</Link> : label;

    return (
        <div className="avatar">
            <div className="avatar-photo">
                <AvatarPhoto {...props} />
            </div>
            <div className={`avatar-data ${dataClass}`}>
                <p className="avatar-label">
                    { labelItem }
                </p>
                {text ? <div className="avatar-text">{ text }</div> : null }
                {avatarAction ? <div className="avatar-action">{ avatarAction }</div> : null }
            </div>
        </div>
    );
};

export default AvatarBlock;
