import React from "react";
import {useQuery} from "@apollo/react-hooks";
import {Link} from "react-router-dom";
import {Spin} from "antd";

import { GET_DRAFT_POSTS } from '../user-tabs-gql';

import CardList from "../../card-list";
import {PostCreateFrontLink} from "../../post-create";


const UserDraftPostsTab = ({ authorId }) => {

    const {loading, data } = useQuery(GET_DRAFT_POSTS, {
        variables: {
            status : 'draft',
            author_id : authorId
        },
        fetchPolicy:"cache-and-network"
    });

    const {
        posts : {
            edges : posts,
            pageInfo
        } = {}
    } = !loading ? data : {};

    const Posts = () => {

        if(loading) return <div className="item-loader"><Spin size="large" /></div>;

        if(pageInfo.total >= 1 ) {
            return (
                <CardList cardItems={posts} type={'draft'} />
            );
        }

        return (
            <div className="user-tabs-message">
                <p>You have no draft posts</p>
                <Link to="/post/create">Create new post?</Link>
            </div>
        );

    };

    return (
        <div className="user-tab">
            <Posts />
            <PostCreateFrontLink />
        </div>
    );

};

export default UserDraftPostsTab;