import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import { useMutation} from "@apollo/react-hooks";
import {gql} from "apollo-boost";
import {Upload, Icon, Row, Col, Form, Button, notification} from "antd";
import { errorNotification } from '../error';

import './post-create.css';

const CREATE_POST = gql`
  mutation CreatePost($files: [Upload!]!) {
      createPost(files: $files){
          label
          message  
          post{
              id
              slug
              title
              description
              media{
                id
                name
                title
                thumbnail
                path
                extension
                alt
                order
              }
              author{
                id
              }
          }
      }
  }`;

class NormalPostCreateForm extends Component {

    state={
        fileList : []
    };

    handleSubmit = (e) => {
        e.preventDefault();

        const { createPost } = this.props;
        const { fileList } = this.state;

        const files = fileList.map( ({ originFileObj }) => {
            return originFileObj;
        });

        createPost({
            variables : {
                files: files
            }
        }).catch((error) => {
            errorNotification(error);
        });

    };

    handleChange = ({ fileList }) => this.setState({ fileList });

    render() {

        const { fileList } = this.state;

        const formUploadProps = {
            accept: 'image/*',
            beforeUpload :  () => { return false; },
            onChange: this.handleChange,
            listType: "picture-card",
            fileList: fileList,
            showUploadList: {
                showPreviewIcon: false
            },
            multiple : true
        };

        const { loading } = this.props;

        return (
            <Form onSubmit={this.handleSubmit}>
                <Form.Item >
                    <Upload {...formUploadProps} >
                        <div>
                            <Icon type="plus" />
                            <div className="ant-upload-text">Add media</div>
                        </div>
                    </Upload>
                </Form.Item>
                <Form.Item>
                    <Button
                        type="primary"
                        htmlType="submit"
                        loading={loading}
                        disabled={fileList.length === 0}
                    >
                        Create draft Post
                    </Button>
                </Form.Item>
            </Form>
        );
    }
}

const PostCreateForm = Form.create({ name: 'validate_other' })(NormalPostCreateForm);

const PostCreate = ({ history }) => {

    const [createPost, { loading }] = useMutation(CREATE_POST, {
        update(cache, { data }){

            const {createPost} = data;
            const {post, label, message} = createPost;

            notification['success']({
                message: label,
                description: message,
            });

            history.push(`/post/edit/${post.slug}`);

        }
    });

    return(
        <div className="post-create">
            <Row type="flex" justify="center">
                <Col xs={23} md={24} lg={20} xl={18} xxl={16} span={14} className="post-create-media">
                    <h2>Create Post</h2>
                    <PostCreateForm
                        createPost={createPost}
                        loading={loading}
                    />
                </Col>
            </Row>
        </div>
    );
};

export default withRouter(PostCreate);