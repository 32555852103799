import React from "react";
import {Carousel, Icon} from "antd";

import './card-media-slider.css';

const NextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
        <button
            className={className}
            style={{ ...style }}
            onClick={onClick}
        >
            <Icon type="right" />
        </button>
    );
};

const PrevArrow = (props) => {
    const { className, style, onClick } = props;

    return (
        <button
            className={className}
            style={{ ...style }}
            onClick={onClick}
        >
            <Icon type="left" />
        </button>
    );
};

const CardMediaSlider = ({ media }) => {

    const settings = {
        dots: true,
        infinite: false,
        arrows: true,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />
    };

    if (!media.length) {
        return false;
    }

    const Slides = media.map(({id, path}) => {
        return (
            <div className="card-image" key={id}>
                <img src={ path } alt="test" />
            </div>
        );
    });

    return (
        <div className="card-media-slider">
            <Carousel { ...settings }>
                { Slides }
            </Carousel>
        </div>
    );
};

export default CardMediaSlider;