import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Avatar, Row, Col, Spin } from "antd";
import UserHeaderActions from "./user-header-actions";
import UserHeaderActionsSelf from "./user-header-actions-self";

import './user-header.css';

class NormalUserHeader extends Component {

    render(){

        const { userLogin, itsMe, user } = this.props;

        return (
            <Row>
                <Col xs={24} sm={24}  md={8} span={8} className="user-header-photo">
                    <Avatar
                        src={ user.avatar }
                        size={150}
                    >
                        { user.initials }
                    </Avatar>
                </Col>
                <Col xs={24} sm={24} md={16} span={16} className="user-header-info">
                    <div className="user-header-data">
                        <h1>{ userLogin }</h1>
                        {itsMe ?
                            <UserHeaderActionsSelf /> :
                            <UserHeaderActions
                                follow={ user.me_following }
                                userId={ user.id }
                                userNickname={ user.nickname }
                            />
                        }
                    </div>
                    <ul className="user-header-statistics">
                        <li className="post-amount-holder">
                            <Link to={`/${userLogin}`}>
                                <b>{ user.counters.published }</b> posts
                            </Link>
                        </li>
                        <li className="followers-holder">
                            <Link to={`/${ user.nickname }/followers`}>
                                <b>{ user.counters.followers }</b> followers
                            </Link>
                        </li>
                        <li className="following-holder">
                            <Link to={`/${ user.nickname }/following/users`}>
                                <b>{ user.counters.following }</b> following
                            </Link>
                        </li>
                    </ul>
                    <div className="user-header-about">
                        { user.name ?
                            <h2 className="user-header-name">
                                { user.name }
                            </h2> : null
                        }
                        { user.about ?
                            <div className="user-header-description">
                                { user.about }
                            </div> : null
                        }
                    </div>
                </Col>
            </Row>
        );
    };
}


const UserHeader = (props) => {

    const { loading } = props;

    return (
        <div className="user-header">
            { loading ?
                <div className="item-loader"><Spin size="large" /></div> :
                <NormalUserHeader {...props} />
            }
        </div>
    );
};

export default UserHeader;