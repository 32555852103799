import React from 'react';
import {Link} from "react-router-dom";
import {Col, Icon} from "antd";

import './card-preview.css';



const CardPreview = (props) => {

    const { post, counters, title, path } = props;

    const fullPath = path ? `${ path }${ post.slug }` : `/post/${ post.slug }` ;

    return (
        <Col xs={24} sm={12} lg={8} span={8} className="card-preview-holder">
            <div className="card-preview">
                <Link to={ fullPath }>
                    <div className="card-image">
                        <img src={ post.media[0].thumbnail } alt={ post.title } />
                    </div>
                    <div className="card-data">
                        { title && post.title ? <h3>{ post.title }</h3> : null }
                        { counters ?
                            <ul>
                                <li>
                                    <span><Icon type="heart" theme='filled' /></span>
                                    <span>{ post.like_count }</span>
                                </li>
                                <li>
                                    <span><Icon type="message" theme='filled' /></span>
                                    <span>{ post.comment_count }</span>
                                </li>
                                <li>
                                    <span><Icon type="file" theme='filled' /></span>
                                    <span>{ post.attached_file_count }</span>
                                </li>
                            </ul>
                        : null}
                    </div>
                </Link>
            </div>
        </Col>
    );
};

export default CardPreview;