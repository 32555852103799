import {gql} from "apollo-boost";

export const GET_POST = gql`
    query GetPost($slug : String!){
        post(slug : $slug){
            id
            title
            description
            slug
            status
            liked
            viewed
            like_count
            view_count
            media_layout
            updated_at
            created_at
            author{
                id
                nickname
                avatar
                initials
                me_following
            }
            media{
                id
                name
                title
                thumbnail
                path
                extension
                alt
                order
            }
            attachments{
                id
                name
                title
                display_url
                extension
                order
                description
                order
            }
            comments(first : 15){
                pageInfo{
                  startCursor
                  endCursor
                  total
                  currentPage
                  lastPage
                }
                edges {
                    node{
                        id
                        content
                        author{
                            id
                            nickname
                        }
                        created_at
                    }
                }
            }
        }
    }`;

export const UPDATE_POST_VIEW = gql`
    mutation PostView($post_id : ID!){
        postView(post_id : $post_id){
            label
            message
            viewed
            view_count
        }
    }`;

export const GET_MORE_COMMENTS = gql`
    query GetPost($slug : String!, $endCursor : String){
        post(slug : $slug){
            id
            slug
            comments(first : 15, after: $endCursor){
                pageInfo{
                  startCursor
                  endCursor
                  total
                  currentPage
                  lastPage
                }
                edges {
                    node{
                        id
                        content
                        author{
                            id
                            nickname
                        }
                        created_at
                    }
                }
            }
        }
    }`;

export const GET_ME = gql`
    query GetMe {
        me @client{
            id
            name
            nickname
            initials
            locale
            role
        } 
    }`;