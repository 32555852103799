import React from "react";
import {Link} from "react-router-dom";
import {Button, Form, Icon, Input, message} from "antd";

import './forgot-password-form.css';
import logo from "../../../assets/logo_45pix.png";

const NormalForgotPasswordForm = ({ form }) => {

    const { getFieldDecorator } =  form;

    const loading = false;

    return (
        <div className="form-holder">
            <div className="logo-img">
                <img src={logo} alt="logo" />
            </div>
            <h2>Forgot password</h2>
            <Form
                className="forgot-password-form"
                onSubmit={
                    (e) => {
                        e.preventDefault();
                        message.warning('Feature in development')
                    }
                }
            >
                <Form.Item>
                    {getFieldDecorator('email', {
                        rules: [{ required: true, message: 'Please input your username!' }],
                    })(
                        <Input
                            prefix={<Icon type="mail" className="form-icon" />}
                            placeholder="Email"
                        />,
                    )}
                </Form.Item>
                <Form.Item>
                    <Button
                        type="primary"
                        htmlType="submit"
                        className="forgot-submit"
                        loading={loading}
                    >
                        Send Forgot Link
                    </Button>
                </Form.Item>
            </Form>
            <div className="divider">
                <div /><div>OR</div><div />
            </div>
            <div className="sign-link-wrap">
                <p>You don't have account? </p>
                <Link to="/registration/" className="sign-link">Sign Up</Link>
            </div>
        </div>
    );

};

const ForgotPasswordForm = Form.create({ name: 'forgot-password' })(NormalForgotPasswordForm);

export default ForgotPasswordForm;