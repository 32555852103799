import React from "react";
import { Spin, Tabs } from "antd";
import {useQuery} from "@apollo/react-hooks";
import {GET_MY_FIELD, GET_USER} from "../follow-gql";

import FollowingUsersTab from "./tabs/following-users-tab";
import FollowingCollectionsTab from "./tabs/following-collections-tab";
import FollowingTagsTab from "./tabs/following-tags-tab";
import FollowingTeamsTab from "./tabs/following-teams-tab";

import './following-tabs.css';


const changeTab = (history, tabKey, userLogin) => {
    history.push(`/${userLogin}/following/${tabKey}`);
};

const FollowingTabs = (props) => {

    const {
        history,
        match : {
            params : {
                userLogin,
                followTab : activeTab
            }
        }
    } = props;

    const { data : { me } } = useQuery(GET_MY_FIELD);

    const {loading, data } = useQuery(GET_USER, {
        variables: { nickname : userLogin },
        fetchPolicy:"cache-and-network"
    });

    const { TabPane } = Tabs;

    const TabButton = ({label}) => {
        return (<span>{label}</span>)
    };

    const {
        user : {
            counters: {
                following_users = 0,
                following_teams = 0,
                following_collections = 0,
                following_tags = 0
            } = {}
        } = {}
    } = data || {};

    return (
        <div className="following-tabs">
            { loading ?
                <div className="item-loader"><Spin size="large" /></div> :
                <Tabs
                    type="card"
                    activeKey={activeTab ? activeTab : 'users'}
                    onTabClick={(key) => {
                        changeTab(history, key, userLogin);
                    }}
                >
                    <TabPane
                        tab={<TabButton label={ `Users (${ following_users })` } />}
                        key="users"
                    >
                        <FollowingUsersTab
                            itsMe={ data.user.id === me.id }
                            me={ me }
                            user={ data.user }
                        />
                    </TabPane>
                    <TabPane
                        tab={<TabButton label={ `Collections (${ following_collections })` } />}
                        key="collections"
                    >
                        <FollowingCollectionsTab
                            itsMe={ data.user.id === me.id }
                        />
                    </TabPane>
                    <TabPane
                        tab={<TabButton label={ `Tags (${ following_tags })` } />}
                        key="tags"
                    >
                        <FollowingTagsTab
                            itsMe={ data.user.id === me.id }
                        />
                    </TabPane>
                    <TabPane
                        tab={<TabButton label={ `Teams (${ following_teams })` } />}
                        key="teams"
                    >
                        <FollowingTeamsTab
                            itsMe={ data.user.id === me.id }
                        />
                    </TabPane>
                </Tabs>
            }
        </div>
    );
};

export default FollowingTabs;