import React from "react";
import {Tabs, Icon, Spin} from "antd";

import UserPostsTab from "./tabs/user-posts-tab";
import UserDraftPostsTab from "./tabs/user-draft-posts-tab";
import UserCollectionsTab from "./tabs/user-collections-tab";
import UserTeamsTab from "./tabs/user-teams-tab";

import './user-tabs.css';

const changeTab = (history, tabKey, userLogin) => {
    if(tabKey === 'posts'){
        history.push(`/${userLogin}`);
    } else {
        history.push(`/${userLogin}/${tabKey}`);
    }
};

const UserTabs = (props) => {

    const {
        activeTab,
        userLogin,
        history,
        itsMe,
        userId,
        loading
    } = props;

    const { TabPane } = Tabs;

    const TabButton = ({label, icon}) => {
        return (<span><Icon type={icon} />{label}</span>)
    };

    return (
        <div className="user-tabs">
            { loading ?
                <div className="item-loader"><Spin size="large" /></div> :
                <Tabs
                    type="card"
                    activeKey={activeTab ? activeTab : 'posts'}
                    onTabClick={(key) => {
                        changeTab(history, key, userLogin);
                    }}
                >
                    <TabPane
                        tab={<TabButton label="Posts" icon="table"/>}
                        key="posts"
                    >
                        <UserPostsTab authorId={userId} itsMe={itsMe} />
                    </TabPane>
                    {itsMe ?
                        <TabPane
                            tab={<TabButton label="Drafts" icon="form"/>}
                            key="drafts"
                        >
                            <UserDraftPostsTab authorId={userId} />
                        </TabPane>
                        : null}
                    <TabPane
                        tab={<TabButton label="Collections" icon="gold"/>}
                        key="collections"
                    >
                        <UserCollectionsTab itsMe={itsMe} />
                    </TabPane>
                    <TabPane
                        tab={<TabButton label="Teams" icon="team"/>}
                        key="teams"
                    >
                        <UserTeamsTab itsMe={itsMe} />
                    </TabPane>
                </Tabs>
            }
        </div>
    );
};

export default UserTabs;