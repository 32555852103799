import React from "react";
import { useQuery } from "@apollo/react-hooks";
import {Spin} from "antd";
import FollowList from "./follow-list";
import { FollowButtonLikedUser } from "../follow-button";
import { GET_USER_LIKED_POST } from '../follow-gql';

const FollowListLikeContainer = (props) => {

    const { postId, myId } = props;

    const { loading, data } = useQuery(GET_USER_LIKED_POST, {
        variables: {
            post_id : postId
        },
        fetchPolicy: "cache-and-network"
    });

    return (
        <div className="follow-list-wrap">
        { loading ?
            <div className="item-loader"><Spin size="large" /></div> :
            <FollowList
                items={ data.listUsersLikedPost.edges }
                myId={ myId }
                objId={ postId }
                FollowButton={ FollowButtonLikedUser }
            />
        }
        </div>
    );
};

export default FollowListLikeContainer;