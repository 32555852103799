import React, {Component} from 'react';
import { Link } from "react-router-dom";
import { useMutation  } from '@apollo/react-hooks';
import { gql } from "apollo-boost";
import { errorNotification } from '../../error';
import { Form, Icon, Input, Button } from 'antd';

import './login-form.css';
import logo from "../../../assets/logo_45pix.png";

const LOGIN_USER = gql`
  mutation LoginUser($data: LoginInput!) {
      loginUser(input: $data){
        access_token
      }
    }
`;

class NormalLoginForm extends Component{

    formSubmit = ({ event, form, loginRequest }) => {
        event.preventDefault();

        form.validateFields((err, values) => {
            if (!err) {
                loginRequest({
                    variables : {
                        data : {
                            username: values.username,
                            password: values.password
                        }
                    },
                });
            }
        });

    };

    render() {

        const { form, loginLoading, loginRequest } = this.props;
        const { getFieldDecorator } =  form;

        return (
            <div className="form-holder">
                <div className="logo-img">
                    <img src={logo} alt="logo" />
                </div>
                <h2>Sign in <br /> to see posts</h2>
                <Form
                    className="login-form"
                    onSubmit={(event) => {
                        this.formSubmit({ event, form, loginRequest });
                    }}
                >
                    <Form.Item>
                        {getFieldDecorator('username', {
                            rules: [{ required: true, message: 'Please input your username!' }],
                        })(
                            <Input
                                prefix={<Icon type="user" className="form-icon" />}
                                placeholder="Username"
                            />,
                        )}
                    </Form.Item>
                    <Form.Item>
                        {getFieldDecorator('password', {
                            rules: [{ required: true, message: 'Please input your Password!' }],
                        })(
                            <Input.Password
                                prefix={<Icon type="lock" className="form-icon" />}
                                type="password"
                                placeholder="Password"
                            />,
                        )}
                    </Form.Item>
                    <Form.Item>
                        <Link to="/forgot-password/" className="login-form-link">Forgot password?</Link>
                        <Button
                            type="primary"
                            htmlType="submit"
                            className="login-form-submit"
                            loading={loginLoading}
                        >
                            Sign in
                        </Button>
                    </Form.Item>
                </Form>
                <div className="divider">
                    <div /><div>OR</div><div />
                </div>
                <div className="sign-link-wrap">
                    <p>You don't have account? </p>
                    <Link to="/registration/" className="sign-link">Sign Up</Link>
                </div>
            </div>
        );
    }
}

const LoginFormContainer = (props) => {

    const [loginRequest, {loading}] = useMutation(
        LOGIN_USER,
        {
            update(cache, { data : { loginUser } }) {

                const { access_token } = loginUser;

                localStorage.setItem("authToken", access_token);
                cache.writeData({ data: {authToken : access_token}});
            },
            onError(error){
                errorNotification(error);
            }
        }
    );

    return(
        <NormalLoginForm
            {...props}
            loginRequest={loginRequest}
            loginLoading={ loading }
        />
    );
};

const LoginForm = Form.create({ name: 'authorization' })(LoginFormContainer);

export default LoginForm;