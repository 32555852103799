import React from 'react';
import {ContainerBlock} from "../../wraps";

const CollectionCreatePage = ({ match }) => {

    //const { postSlug } = match.params;

    return (
        <ContainerBlock>
            <h2>Create collection</h2>
        </ContainerBlock>
    );
};

export default CollectionCreatePage;