import React from "react";
import AvatarBlock from "../../avatar";


import './follow-list.css';

const FollowList = ({items, myId, objId, FollowButton}) => {

    if(!items.length){
        return null;
    }

    const RenderItems = items.map(({ node }) => {

        const { author } = node;

        return (
            <li key={node.id} className="follow-item">
                <div>
                    <AvatarBlock
                        photoUrl={author.avatar}
                        initials={author.initials}
                        link={`/${author.nickname}`}
                        label={author.nickname}
                        text={author.name}
                    />
                    { author.id !== myId ?
                        <FollowButton
                            objId={ objId }
                            author={ author }
                        /> : null
                    }
                </div>
            </li>
        );
    });

    return (
        <ul className="follow-list">
            { RenderItems }
        </ul>
    );
};

export default FollowList;