import {gql} from "apollo-boost";

export const GET_USER_LIKED_POST =gql`
    query ListUsersLikedPost($post_id : ID!){
        listUsersLikedPost(post_id: $post_id){
            pageInfo{
                total
                endCursor
                currentPage
                lastPage
            }
            edges{
                node{
                    id
                    author{
                        id
                        nickname
                        initials
                        name
                        avatar
                        me_following
                    }
                    post{
                        id
                    }
                }
            }
        }
     }`;


export const GET_USER_FOLLOWERS =gql`
    query UserFollowers($user_id : ID!){
        userFollowers(user_id: $user_id){
            pageInfo{
                total
                endCursor
                currentPage
                lastPage
            }
            edges{
                node{
                    id
                    nickname
                    initials
                    name
                    avatar
                    me_following
                    itsMe @client
                }
            }
        }
     }`;

export const GET_USER_FOLLOWING =gql`
    query UserFollowing($user_id : ID!){
        userFollowing(user_id: $user_id){
            pageInfo{
                total
                endCursor
                currentPage
                lastPage
            }
            edges{
                node{
                    id
                    nickname
                    initials
                    name
                    avatar
                    me_following
                    itsMe @client
                    counters : user_counters{
                        following_users
                        following_teams
                        following_collections
                        following_tags
                    }
                }
            }
        }
     }`;

export const FOLLOW_USER = gql`
  mutation FollowUser($user_id : ID!) {
      followUser(user_id : $user_id){
        label
        message
        me_following
      }
    }`;


export const GET_USER=gql`
    query GetUser($nickname : String!){
        user(nickname : $nickname){
            id
            nickname
            name
            initials
            email
            status
            locale
            role
            gender
            avatar
            about
            me_following
            itsMe @client
            counters : user_counters{
                published : public_posts
                followers
                following_users
                following_teams
                following_collections
                following_tags
                following
                
            }
        }
    }`;

export const GET_MY_FIELD = gql`
    query GetMe{
        me @client{
            id
            nickname
        }
    }`;