import React from "react";
import {useQuery} from "@apollo/react-hooks";
import {gql} from "apollo-boost";
import {Link} from "react-router-dom";
import {Icon, Spin, message} from "antd";

import './header-actions.css';



const GET_MY_FIELD = gql`
    query GetMe{
        me{
            nickname @client
        }
    }`;

const HeaderActions = () => {

    const { loading, data : { me } } = useQuery(GET_MY_FIELD);

    if(loading) return <div className="block-loader"><Spin size="large" tip="Loading..." /></div>;

    return(
        <ul className="header-actions">
            <li>
                <Link to="/posts" title="All posts" className="header-action-posts">
                    <Icon type="compass" />
                </Link>
            </li>
            <li>
                <Link
                    to="#"
                    title="Notifications"
                    className="header-action-posts"
                    onClick={ () => message.warning('Feature in development') }
                >
                    <Icon type="heart" />
                </Link>
            </li>
            <li>
                <Link to={`/${me.nickname}`} title="Profile" className="header-action-profile">
                    <Icon type="user" />
                </Link>
            </li>
        </ul>
    );
};


export default HeaderActions;