import {notification} from "antd";

const errorNotification = (error) => {

    error.graphQLErrors.map(({ message: label, extensions : {reason} }) => {
        notification['error']({
            message: label ,
            description: reason
        });

        return false;
    });


};

export default errorNotification;