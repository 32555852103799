import React from "react";
import {Button, message, notification} from "antd";
import { FOLLOW_USER, GET_USER } from './user-header-gql';
import {useApolloClient, useMutation} from "@apollo/react-hooks";
import {errorNotification} from "../error";

const UserHeaderActions = ({ follow, userId, userNickname }) => {

    const client = useApolloClient();

    const [ onFollowClick, { loading : followLoading } ] = useMutation(FOLLOW_USER, {
        update : (cache, { data }) => {

            const { followUser } = data;
            const { label, message, me_following, counters } = followUser;

            const {user} = client.readQuery({
                query: GET_USER,
                variables: {
                    nickname: userNickname,
                }
            });

            client.writeQuery({
                query: GET_USER,
                data: {
                    user: {...user, me_following, counters}
                },
            });

            notification['success']({
                message: label,
                description: message
            });
        }
    });

    return (
        <div className="user-header-actions">
            <Button
                loading={ followLoading }
                className={`user-header-but ${!follow ? 'follow' : null}`}
                onClick={
                    () => {
                        if(!followLoading) {
                            onFollowClick({
                                variables: {
                                    user_id: userId,
                                }
                            }).catch((error) => {
                                errorNotification(error);
                            });
                        }
                    }
                }
            >
                { follow ? 'Following' : 'Follow' }
            </Button>
            <Button
                className="user-header-small-but"
                shape="circle"
                icon="ellipsis"
                onClick={ () => message.warning('Feature in development') }
            />
        </div>
    );
};

export default UserHeaderActions;