import { gql } from 'apollo-boost';

export const typeDefs = gql`
   
    extend type Query {
        me: User
        authToken: string!
    }
`;


const GET_AUTH_TOKEN = gql`
  {
    authToken @client
  }
`;

const GET_ME = gql`
    query GetMe{
        me @client{
            id
            nickname
        }
    }`;

export const resolvers = {
    Query: {
        me: () => {


            // cache.readQuery({
            //     query: GET_CURRENT_USER,
            // });

            //console.log('CURRENT_USER_QUERY_RESOLVER');

        },
    },
    User: {
      itsMe: (_root, _args, { cache }) => {

          const { me } = cache.readQuery({
               query: GET_ME,
          });

          return _root.id === me.id;
      }
    },
    Mutation: {
        setAuthToken: (_root, { authToken }, { cache }) => {

            cache.writeQuery({
                query: GET_AUTH_TOKEN,
                data: { authToken: authToken }
            });

            console.log('LOGIN_UPDATE_RESOLVER');

            return !authToken;
        }

    }
};