import React from "react";
import { useQuery } from "@apollo/react-hooks";
import {Spin} from "antd";
import FollowList from "./follow-list";
import { FollowButtonFollowersUser } from "../follow-button";
import { GET_USER_FOLLOWERS } from '../follow-gql';


const FollowListFollowersContainer = (props) => {

    const { user, me } = props;

    const { loading, data } = useQuery(GET_USER_FOLLOWERS, {
        variables: {
            user_id : user.id
        },
        fetchPolicy: "cache-and-network"
    });

    const nodes = (!loading) ?
        data.userFollowers.edges.map( ({ node }) => {
            return {
                node : {
                    id: node.id,
                    author: node
                }
            }
        }) : [];

    if(!loading && !nodes.length){
        return (
            <div className="follower-message">
                <p>{ user.id === me.id ? 'You' : 'User' } have no Followers</p>
            </div>
        );
    }

    return (
        <div className="follow-list-wrap">
            { loading ?
                <div className="item-loader"><Spin size="large" /></div> :
                <FollowList
                    items={ nodes }
                    myId={ me.id }
                    objId={ user.id }
                    FollowButton={ FollowButtonFollowersUser }
                />
            }
        </div>
    );
};

export default FollowListFollowersContainer;