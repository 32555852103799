import React from 'react';
import {Input, message} from 'antd';

const SearchBlock = () => {

    const { Search } = Input;

    return (
        <div className="search-holder">
            <Search
                placeholder="input search text"
                onSearch={() => message.warning('Feature in development') }
            />
        </div>
    );
};

export default SearchBlock;