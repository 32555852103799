import React from 'react';
import {Col, Row} from "antd";

import './сontainer-block.css';


const ContainerBlock = ({children, className=null}) => {
    return (
        <Row className={className} type="flex" justify="center" gutter={0}>
            <Col xs={23} md={21} lg={19} xl={16} xxl={15} span={24}>
                { children }
            </Col>
        </Row>
    );
};

export default ContainerBlock;