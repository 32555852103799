import React from 'react';
import {ContainerBlock} from "../../wraps";
import {MostRecentPosts, TopPosts} from '../../posts'

const PostsPage = () => {

    return (
        <ContainerBlock>
            <TopPosts/>
            <MostRecentPosts />
        </ContainerBlock>
    );
};

export default PostsPage;