import {gql} from "apollo-boost";

export const GET_POST = gql`
    query GetPost($slug : String!){
        post(slug : $slug){
            id
            title
            description
            slug
            status
            media_layout
            author{
                id
                nickname
            }
            media{
                id
                name
                title
                thumbnail
                path
                extension
                alt
                order
            }
            attachments{
                id
                name
                title
                display_url
                extension
                order
                description
                order
            }
        }
    }`;

export const GET_CURRENT_USER = gql`
    query GetMe {
        me @client{
            id
            nickname
            role
        }
    }`;

export const UPDATE_POST = gql`
  mutation UpdatePost($post_id : ID!, $input: PostInput!) {
      updatePost(post_id : $post_id, input: $input){
        label
        message
        post{
            id
            title
            description
            slug
            status
        }
      }
    }`;

export const TRASH_POST = gql`
  mutation TrashPost($post_id : ID!) {
      trashPost(post_id : $post_id){
        label
        message
      }
    }`;

export const ADD_MEDIA = gql`
  mutation AddMedia($post_id : ID!, $files: [Upload!]!) {
      addMedia(post_id : $post_id, files: $files){
        label
        message  
        post{
            id
            title
            description
            slug
            status
            media{
                id
                name
                title
                thumbnail
                path
                extension
                alt
                order
            }
        }
      }
    }`;

export const DELETE_MEDIA = gql`
  mutation DeleteMedia($media_id : ID!) {
      deleteMedia(media_id : $media_id){
        label
        message   
      }
    }`;

export const ADD_ATTACHMENT = gql`
  mutation AddAttached($post_id : ID!, $files: [Upload!]!) {
      addAttached(post_id : $post_id, files: $files){
        label
        message  
        post{
            id
            title
            description
            slug
            status
            attachments{
                id
                name
                title
                display_url
                extension
                order
                description
                order
            }
        }
      }
    }`;

export const DELETE_ATTACHMENT = gql`
  mutation DeleteAttached($attached_id : ID!) {
      deleteAttached(attached_id : $attached_id){
        label
        message   
      }
    }`;