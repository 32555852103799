import React, {useState} from "react";
import {Button, message, Modal} from "antd";
import { FollowListLikeContainer } from "../follow/follow-list";

import './card-like-amount.css'

const CardLikeAmount = (props) => {

    const { likeCount } = props;
    const [ showPopup, setShowPopup ] = useState(false);

    return(
        <span className="like-amount">
            <Button
                onClick={
                    () => { likeCount < 1 ?
                        message.warning('Nobody likes this post.') :
                        setShowPopup(true)
                    }
                }
            >
                { likeCount > 1 ?
                    `${ likeCount } likes` :
                    `${ likeCount } like`
                }
            </Button>
            <Modal
                title={<h3>Likes</h3>}
                destroyOnClose={ true }
                footer={null}
                visible={ showPopup }
                closable={ true }
                onCancel={ () => setShowPopup(false) }
                wrapClassName="modal-actions likes-modal"
                centered
            >
                <FollowListLikeContainer { ...props } />
            </Modal>
        </span>
    );
};

export default CardLikeAmount;