import {useApolloClient, useMutation} from "@apollo/react-hooks";
import {ADD_MEDIA, DELETE_MEDIA, GET_POST} from "../post-update-gql";
import {Icon, notification, Upload} from "antd";
import React from "react";

import './post-upload-media.css';
import {errorNotification} from "../../error";

const PostUploadMedia = (props) => {

    const { defaultMedia, postId, postSlug } =  props;
    const client = useApolloClient();

    const [addMedia, { loading }] = useMutation(ADD_MEDIA);
    const [deleteMedia] = useMutation(DELETE_MEDIA);

    const fileList = defaultMedia.map(({ id, title, thumbnail, path }) => {
        return (
            {
                uid : id,
                name : title,
                url : path,
                thumbUrl : thumbnail,
                status : 'done'
            }
        )
    });

    const formUploadProps = {
        accept: 'image/*',
        beforeUpload :  (file) => {

            addMedia({
                variables : {
                    post_id : postId,
                    files: [file]
                },
                update(cache, { data }) {

                    const {addMedia} = data;
                    const {label, message} = addMedia;

                    const {post} = client.readQuery({
                        query: GET_POST,
                        variables: {
                            slug: postSlug,
                        }
                    });

                    client.writeQuery({
                        query: GET_POST,
                        data: {
                            post: {...post, ...addMedia.post}
                        },
                    });

                    notification['success']({
                        message: label,
                        description: message
                    });
                }
            }).catch((error) => {
                errorNotification(error);
            });

            return false;
        },
        onRemove: (file) => {

            if(file.status === 'done' && defaultMedia.length > 1){

                deleteMedia({
                    variables : {
                        media_id : file.uid
                    },
                    update: (cache, { data }) => {

                        const {
                            deleteMedia : {
                                label,
                                message
                            }
                        } = data;

                        const {post} = client.readQuery({
                            query: GET_POST,
                            variables: {
                                slug: postSlug,
                            }
                        });

                        const newMedia = post.media.filter(item => item.id !== file.uid);

                        client.writeQuery({
                            query: GET_POST,
                            data: {
                                post: { ...post, media:[ ...newMedia ] }
                            },
                        });

                        notification['success']({
                            message: label,
                            description: message
                        });

                    }
                }).catch((error) => {
                    errorNotification(error);
                });

            }
        },
        fileList: fileList,
        listType: "picture-card",
        showUploadList: {
            showPreviewIcon: false,
            showDownloadIcon: false
        },
        multiple : true
    };

    return(
        <div className="post-update-media">
            <h4>Media ({ defaultMedia.length })</h4>
            <Upload {...formUploadProps}>
                <div>
                    { loading ? <Icon type="loading" /> : <Icon type="plus" /> }
                    <div className="ant-upload-text">Upload</div>
                </div>
            </Upload>
        </div>
    );
};

export default PostUploadMedia;