import React, {Component} from "react";
import {useMutation, useQuery} from "@apollo/react-hooks";
import { withRouter } from 'react-router-dom';
import {Col, Row, Spin, Modal, Button, notification} from "antd";
import PostDataForm from "./post-data-form";
import PostUploadMedia from "./post-upload-media";
import PostUploadAttachments from "./post-upload-attachments";
import {errorNotification, ErrorResult} from "../error";
import {GET_POST, GET_CURRENT_USER, TRASH_POST} from './post-update-gql';

import './post-update.css';


class RemoveButton extends Component{

    state = { visible: false };

    toggleModal = (state) => {
        this.setState({
            visible: state,
        });
    };

    confirmRemove = () => {
        const {
            removePost,
            postId,
            history,
            myNickname
        } = this.props;

        removePost({
            variables: {
                post_id: postId,
            },
            update: (cache, { data }) => {

                const {
                    trashPost:{
                        label,
                        message
                    }
                } = data;

                notification['success']({
                    message: label,
                    description: message
                });

                this.toggleModal(false);

                history.push(`/${myNickname}/drafts`);
            }
        }).catch((error) => {
            errorNotification(error);
        });
    };

    render(){

        const { removeLoader } = this.props;

        return(
            <div>
                <Button
                    type="danger"
                    onClick={() => this.toggleModal(true)}
                >
                    Remove post
                </Button>
                <Modal
                    title="Remove draft post"
                    visible={this.state.visible}
                    onOk={ () => this.confirmRemove()}
                    onCancel={() => this.toggleModal(false)}
                    okText={'Yes'}
                    okButtonProps={{ loading : removeLoader }}
                    okType={'danger'}
                    cancelText={'No'}
                >
                    <p>Are you sure you want to delete this post?</p>
                </Modal>
            </div>
        );
    }
}

const PostUpdate = ({ match, history }) => {

    const { postSlug } =  match.params;

    const {loading, error, data} = useQuery(GET_POST, {
        variables: { slug : postSlug },
        fetchPolicy:"cache-and-network"
    });

    const { data : { me } } = useQuery(GET_CURRENT_USER);

    const [trashPost, { loading : trashLoading }] = useMutation(TRASH_POST);

    if(loading) return (
        <div className="post-update">
            <div className="item-loader"><Spin size="large" /></div>
        </div>
    );

    if(error || data.post === null || me.id !== data.post.author.id){
        return (
            <ErrorResult
                error={error}
                goToLink={`/${me.nickname}/drafts`}
                goToText="Go to all drafts"
            />
        );
    }

    const { id, slug, media, attachments } = data.post;

    return (
        <div className="post-update">
            <Row type="flex" gutter={24} justify="center">
                <Col xs={23} md={24} lg={20} xl={18} xxl={16} span={14}>
                    <h2>
                        <span>Update Post</span>
                        <RemoveButton
                            postId={id}
                            removePost={ trashPost }
                            removeLoader={ trashLoading }
                            history={ history }
                            myNickname={ me.nickname }
                        />
                    </h2>
                    <PostUploadMedia
                        postId={id}
                        postSlug={ slug }
                        defaultMedia={ media }
                    />
                    <PostUploadAttachments
                        postId={id}
                        postSlug={ slug }
                        defaultAttachments={ attachments }
                    />
                </Col>
            </Row>
            <Row type="flex" gutter={24} justify="center">
                <Col xs={23} md={24} lg={20} xl={18} xxl={16} span={14}>
                    <PostDataForm
                        post={ data.post }
                        history={ history }
                    />
                </Col>
            </Row>
        </div>
    );
};

export default withRouter(PostUpdate);