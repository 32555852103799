import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Linkify from 'react-linkify';
import {gql} from "apollo-boost";
import { Button, Col, Modal } from "antd";
import CardHero from "../card-hero";
import CommentsList from "../comments-list";
import CardMediaSlider from "../card-media-slider";
import { CardActionsContainerDashboard } from "../card-actions";
import { CardCommentFormContainerDashboard } from "../card-comment-form";
import AttachmentsList from "../attachments-list";

import './card-dashboard.css';
import {useQuery} from "@apollo/react-hooks";
import CardLikeAmount from "../card-like-amount";


const GET_ME = gql`
    query GetMe {
        me @client{
            id
            name
            nickname
            initials
            locale
            role
        } 
    }`;

const CardDescription = (props) => {

    const {
        description,
        length,
        setShowMore,
        showMore
    } = props;

    let readyText, showMoreBut;

    if(description === null){
        return null;
    }

    if(length && description.length > length && !showMore) {

        if(description.match(/\n/) && description.match(/\n/).index < length){
            readyText = description.slice(0, description.lastIndexOf('\n')) + '...';
        } else {
            readyText = description.slice(0, description.slice(0, length).lastIndexOf(' ')) + '...';
        }
        showMoreBut = <button className="show-more" onClick={ () => setShowMore(true) }>more</button>;
    } else {
        readyText = description;
        showMoreBut = null;
    }

    const componentDecorator = (href, text, key) => (
        <a href={href} key={key} target="_blank" rel="noopener noreferrer">
            {text}
        </a>
    );

    return (
        <p>
            <Linkify componentDecorator={ componentDecorator }>{ readyText }</Linkify>
            { showMoreBut }
        </p>
    );
};

const CardAttachmentsModal = ({attachments, showAttachments, setShowAttachments}) => {

    return (
        <Modal
            title={'Post attachments'}
            footer={null}
            visible={ showAttachments }
            onCancel={ () => setShowAttachments(false)}
            wrapClassName="modal-attachments-list"
            centered
        >
            <AttachmentsList attachments={ attachments } />
        </Modal>
    );

};

const CardDashboard = (props) => {

    const {data : { me } } = useQuery(GET_ME);

    const { post } = props;

    const [ lastCommentId, setLastCommentId ] = useState('');
    const [ showMore, setShowMore ] = useState(false);
    const [ showAttachments, setShowAttachments ] = useState(false);

    let cardClasses = 'card-dashboard';

    return (
        <Col span={24} className="card-dashboard-holder">
            <div className={cardClasses}>
                <CardHero post={ post } me={me} />
                <div className="card-body">
                    <CardMediaSlider  media={ post.media } />
                    <CardActionsContainerDashboard
                        postId={ post.id }
                        postSlug={ post.slug }
                        liked={ post.liked }
                    />
                    <div className="card-add-info">
                        <CardLikeAmount
                            likeCount={ post.like_count }
                            postId={ post.id }
                            myId={ me.id }
                        />
                        <span className="view-amount">
                            { post.view_count > 1 ?
                                `${post.view_count} views` :
                                `${post.view_count} view`
                            }
                        </span>
                    </div>
                    <div className="card-text">
                        { post.title ? <h3 className="card-title">{ post.title }</h3> : null }
                        <CardDescription
                            description={ post.description }
                            showMore={ showMore }
                            setShowMore={ setShowMore }
                            length={ 128 }
                        />
                    </div>
                    <div className="card-comments">
                        {
                            post.comment_count > 3 ?
                                <Link to={`/post/${ post.slug }`} className="more-link">
                                    View all <span>{ post.comment_count }</span> comments
                                </Link> : null
                        }
                        <CommentsList
                            comments={post.comments.edges}
                            setLastCommentId={setLastCommentId}
                            amount={2}
                            withTime={ false }
                        />
                    </div>
                    { post.attached_file_count > 0 ?
                        <div className="card-attachments">
                            <Button
                                type={'link'}
                                onClick={ () => setShowAttachments(true) }
                            >
                                { `View All ${ post.attached_file_count } attachments`}
                            </Button>
                            <CardAttachmentsModal
                                attachments={ post.attachments }
                                setShowAttachments={setShowAttachments}
                                showAttachments={showAttachments}
                            />
                        </div> : null
                    }
                </div>
                <div className="card-footer">
                    <div className="card-footer-wrap">
                        <CardCommentFormContainerDashboard
                            postId={ post.id }
                            postStatus={ post.status }
                            postSlug={ post.slug }
                            lastCommentId={lastCommentId}
                        />
                    </div>
                </div>
            </div>
        </Col>
    );
};

export default CardDashboard;
