import {gql} from "apollo-boost";

export const TRASH_POST = gql`
  mutation TrashPost($post_id : ID!) {
      trashPost(post_id : $post_id){
        label
        message
      }
    }`;

export const GET_DASHBOARD_POSTS = gql`
    query Dashboard{
        dashboard{
            pageInfo{
                startCursor
                endCursor
                total
                currentPage
                lastPage
            }
            edges{
                node{
                    id
                    slug
                    liked
                    viewed
                    like_count
                    view_count
                    comment_count
                    author {
                        id
                        me_following
                    }
                }   
            }
        }
    }`;

export const FOLLOW_USER = gql`
  mutation FollowUser($user_id : ID!) {
      followUser(user_id : $user_id){
        label
        message
        me_following
      }
    }`;

export const GET_POST = gql`
    query GetPost($slug : String!){
        post(slug : $slug){
            id
            slug
            status
            liked
            viewed
            like_count
            author {
                id
                me_following
            }
        }
    }`;