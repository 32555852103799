import React from 'react';
import {ContainerBlock} from "../../wraps";
import PostUpdate from "../../post-update";

const PostUpdatePage = () => {
    return (
        <ContainerBlock>
            <PostUpdate />
        </ContainerBlock>
    );
};

export default PostUpdatePage;