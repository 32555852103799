import React, {Component} from "react";
import {gql} from "apollo-boost";
import {useMutation, useQuery} from "@apollo/react-hooks";
import {Button, Form, Input, notification} from "antd";
import {errorNotification} from "../../error";

import './change-password-form.css';



const CHANGE_PASSWORD = gql`
  mutation ChangePassword($input: ChangePassword!) {
      changePassword(input: $input){
          label
          message  
      }
  }`;

const GET_ME = gql`
    query GetMe {
        me @client{
            id
        }
    }`;

class NormalChangePasswordForm extends Component {

    state = {
        confirmDirty: false
    };

    formSubmit = ({ event, form, changePasswordRequest }) => {
        event.preventDefault();

        form.validateFields((err, values) => {
            if (!err) {

                changePasswordRequest({
                    variables : {
                        input: {
                            password: values.current,
                            new_password: values.password,
                            new_password_confirmation: values.confirm,
                        }
                    }
                }).catch((error) => {
                    errorNotification(error);
                });
            }
        });
    };

    compareToFirstPassword = (rule, value, callback) => {
        const { form } = this.props;
        if (value && value !== form.getFieldValue('password')) {
            callback('Two passwords that you enter is inconsistent!');
        } else {
            callback();
        }
    };

    validateToNextPassword = (rule, value, callback) => {
        const { form } = this.props;
        if (value && this.state.confirmDirty) {
            form.validateFields(['confirm'], { force: false });
        }
        callback();
    };


    render() {

        const { form, id, requestLoading, changePasswordRequest } = this.props;
        const {getFieldDecorator} = form;

        const formItemLayout = {
            labelCol: {span: 6},
            wrapperCol: {span: 16},
        };

        return (
            <Form
                className="profile-form"
                layout="horizontal"
                onSubmit={(event) => {
                    this.formSubmit({event, form, id, changePasswordRequest});
                }}
            >
                <Form.Item label="Current" {...formItemLayout}>
                    {getFieldDecorator('current', {
                        rules: [
                            {required: true, message: 'Please input your current password'}
                        ]
                    })(
                        <Input.Password placeholder="Enter your current password" />
                    )}
                </Form.Item>
                <Form.Item label="New" {...formItemLayout}>
                    {getFieldDecorator('password', {
                        rules: [
                            {min: 8, message: 'Password must be at least 8 characters'},
                            {required: true, message: 'Please input new Password'},
                            {
                                validator: this.validateToNextPassword,
                            }
                        ]
                    })(
                        <Input.Password placeholder="Enter new password" />
                    )}
                </Form.Item>
                <Form.Item label="Confirm" {...formItemLayout}>
                    {getFieldDecorator('confirm', {
                        rules: [
                            {required: true, message: 'Please confirm Password'},
                            {
                                validator: this.compareToFirstPassword,
                            }
                        ]
                    })(
                        <Input.Password placeholder="Repeat new password" />
                    )}
                </Form.Item>
                <Form.Item wrapperCol={{span: 16, offset: 6}}>
                    <Button
                        loading={requestLoading}
                        type="primary"
                        htmlType="submit">
                        Change Password
                    </Button>
                </Form.Item>
            </Form>
        );
    };
}

const ChangePasswordContainer = (props) => {

    const [changePasswordRequest, { loading }] = useMutation(CHANGE_PASSWORD,
        {
            update(cache, { data }) {

                const {
                    changePassword : {
                        label,
                        message
                    }
                } = data;

                notification['success']({
                    message: label,
                    description: message,
                });

            }
        });

    const {data : { me }} = useQuery(GET_ME);

    return (
        <NormalChangePasswordForm
            {...props}
            changePasswordRequest={changePasswordRequest}
            requestLoading={loading}
            id={me.id}
        />
    );
};

const ChangePasswordForm = Form.create({ name: 'change_password' })(ChangePasswordContainer);

export default ChangePasswordForm;