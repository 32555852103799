import {gql} from "apollo-boost";

export const GET_DRAFT_POSTS = gql`
    query GetDraftPosts($status: Status!, $author_id: ID){
        posts(status: $status, author_id: $author_id){
            pageInfo{
              count
              total
            }
            edges{
                 node{
                    id
                    title
                    description
                    slug
                    status
                    media{
                        id
                        name
                        title
                        thumbnail
                        path
                        extension
                        alt
                        order
                    }
                }
            }
        }   
    }`;

export const GET_PUBLIC_POSTS = gql`
    query GetPublicPosts($status: Status!, $author_id: ID){
        posts(status: $status, author_id: $author_id){
            pageInfo{
              count
              total
            }
            edges{
                 node{
                    id
                    title
                    description
                    slug
                    status
                    media{
                        id
                        name
                        title
                        thumbnail
                        path
                        extension
                        alt
                        order
                    }
                    like_count
                    comment_count
                    attached_file_count 
                }
            }
        }   
    }`;