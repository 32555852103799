import React from 'react';
import {Row, Col, Affix, Spin} from 'antd';
import {gql} from "apollo-boost";
import {useQuery} from "@apollo/react-hooks";
import { ContainerBlock }  from "../../wraps";
import { DashboardPosts } from "../../posts";
import AvatarBlock from "../../avatar";
import { PostCreateFrontLink } from "../../post-create";

const GET_MY_FIELD = gql`
    query GetMe{
        me @client{
            nickname
            name
            initials
            avatar
        }
    }`;

const HomePage = () => {

    const { loading, data : { me } } = useQuery(GET_MY_FIELD);

    return (
        <ContainerBlock className="dashboard-wrap">
            <Row type="flex" gutter={ 24 } justify="center">
                <Col xs={24} sm={24} lg={18} xl={16} xxl={14} span={16} className="content">
                    <DashboardPosts />
                </Col>
                <Col xs={0} sm={0} lg={6} xl={6} xxl={6} span={8}>
                    <Affix offsetTop={80}>
                        <div>
                            { loading ?
                                <div className="item-loader"><Spin size="large" /></div> :
                                <AvatarBlock
                                    photoUrl={me.avatar}
                                    initials={me.initials}
                                    link={`/${me.nickname}`}
                                    label={me.nickname}
                                    text={me.name}
                                    size={50}
                                />
                            }
                        </div>
                    </Affix>
                </Col>
            </Row>
            <PostCreateFrontLink />
        </ContainerBlock>
    );
};

export default HomePage;